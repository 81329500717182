// Modules
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FlatList } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Ionicons, Entypo, MaterialIcons } from '@expo/vector-icons'

// Components
import { Head } from './Head'
import { DisabledV } from '../Disabled'
import { HomeS } from '../../skeleton/HomeS'

// Styles and Utils
import { ShadowBox } from '../../generalStyles'
import { numberFormat, dateFormat, hourFormat, mq } from '../../validation'
import { Container, ViewCredit, BtnContent, Title, SubTitle, Row, ViewMore, TitleHead, PaymentButton } from './styled'
import { StatesBar } from './StatesBar'
import { View } from 'react-native'

/**
 * Home View
 * @param {any} props Properties Home View
 * @returns {ReactElement} HomeView Component
 */
export const HomeV = ({ data: { credits, loading, navigation, uState, disabledCredit, transactionStatus, dataResponseAdo, adoParameters, obligation, loadObligation }, handleOpen, handleState, paymentProviderData, handleEnrollment, recharge, dataUserById, payroll }) => {
    // States and Variables,
    const theme = useTheme()
    const [widthRow, setWidthRow] = useState()
    return (
        <Container>
            {loading ?
                <>
                    <Head navigation={navigation} handleState={handleState} payroll={payroll} data={{ credits, disabledCredit, transactionStatus, obligation, loadObligation }} />
                    <HomeS style={{ alignSelf: 'center' }} />
                </>
                :
                <FlatList
                    data={credits}
                    keyExtractor={item => item.cId}
                    ListHeaderComponent={uState === 0 ? <DisabledV /> : <Head dataUserById={dataUserById} payroll={payroll} navigation={navigation} handleState={handleState} data={{ credits, disabledCredit, transactionStatus, dataResponseAdo, adoParameters, obligation, loadObligation }} handleEnrollment={handleEnrollment} recharge={recharge} />}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item }) => (
                        <ViewCredit style={ShadowBox}>
                            <Row>
                                <Title color={theme.PColor}>{item.cName}</Title>
                                {!item.check && <Title>{item.cConsecutive}</Title>}
                            </Row>
                            <Row
                                onLayout={({ nativeEvent }) => setWidthRow(nativeEvent?.layout.width)}
                            >
                                <StatesBar
                                    {...item}
                                    widthRow={widthRow}
                                />
                            </Row>
                            {(!item.check && item.creditFormat?.cfReqValAft !== 0) && <Row style={{ paddingTop: mq(15), width: '65%', display: 'flex', justifyContent: 'space-between' }}>
                                <View style={{ width: '50%' }}>
                                    <SubTitle family='PFontMedium' size={mq(16)} color={theme.SFColor}>$ {numberFormat(item?.balanceToday)}</SubTitle>
                                </View>
                                <View style={{ width: '50%' }}>
                                    {(item?.cState === 6 || ((item?.cState === 2 || item?.cState === 3 || item?.cState === 4 || item?.cState === 5) && item?.creditOrigins?.length > 0 && !item?.creditOrigins[0]?.coApplied) && paymentProviderData !== undefined) && <Row style={{ justifyContent: 'flex-end' }}>
                                        <PaymentButton onPress={() => navigation.navigate('PaymentSummary', {
                                            ...item, creditName: item?.cName, consecutiveCredit: item?.cConsecutive, quotaValue: item?.cValCou,
                                            creditFirstFee: item?.cFirFee, cPayDat: item?.cPayDat, cId: item?.cId, cState: item?.cState, navigation, paymentProvider: paymentProviderData
                                        })}>
                                            <MaterialIcons name="payment" size={mq(15)} color={theme.BGColor} style={{ marginRight: '8px' }} />
                                            <Title size={mq(13)} style={{ padding: 0, color: '#ffff' }}>Pago</Title>
                                        </PaymentButton>
                                    </Row>}
                                </View>
                            </Row>}
                            {/* {(!item.check && item.creditFormat?.cfReqValAft !== 0 && item?.cState === 6) && <Row style={{ paddingTop: mq(15), justifyContent: 'flex-start' }}>
                                <PaymentButton style={{ marginLeft: '15px' }} onPress={() => navigation.navigate('PaymentSummary', { creditName: item?.cName, consecutiveCredit: item?.cConsecutive, quotaValue: item?.cValCou, cFirFee: item?.cFirFee, cPayDat: item?.cPayDat, navigation })}>
                                    <MaterialIcons name="payment" size={mq(19)} color={theme.BGColor} style={{ marginRight: '8px' }} />
                                    <Title size={mq(13)} style={{ padding: 0, color: '#ffff' }}>Pago</Title>
                                </PaymentButton>
                            </Row>} */}
                            {(item.cPending || (item.cCard === 1 && !item.cCarDoc && item.cState === 3) || (item.cState === 10 && item.cAddInf !== 1)) ?
                                <>
                                    <Row paddingVertical={mq(6)}>
                                        <SubTitle color={theme.EColor}>En espera</SubTitle>
                                        <SubTitle family='PFontLight' size={mq(8)}>{dateFormat(new Date())} - {hourFormat(new Date())}</SubTitle>
                                    </Row>
                                    {item.cEdit === 1 &&
                                        <SubTitle>
                                            Algunos campos incorrectos, verifique la bandeja de mensajes, y corrija los errores para continuar con el proceso.
                                        </SubTitle>
                                    }
                                    {(item.cCard === 1 && !item.cCarDoc && item.cState === 3) &&
                                        <SubTitle>
                                            Es necesario cargar los certificados de la cartera para seguir con el proceso.
                                        </SubTitle>
                                    }
                                    {(item.cPending === 1 && item.cEdit !== 1) &&
                                        <SubTitle>
                                            Ha sido colocado en espera, revise la bandeja de mensajes estará recibiendo notificaciones del por que.
                                        </SubTitle>
                                    }
                                    {(item.cState === 10 && item.cAddInf !== 1) &&
                                        <SubTitle>
                                            Es necesario cargar el restante de información para continuar con el proceso.
                                        </SubTitle>
                                    }
                                    <Row paddingVertical={mq(6)}>
                                        {(item.cCard === 1 && !item.cCarDoc && item.cState === 3) &&
                                            <BtnContent onPress={() => navigation.navigate('DocumentCard', { cId: item.cId, creditCards: item.creditCards })}>
                                                <Entypo name='upload-to-cloud' size={mq(12)} color={theme.SColor} />
                                                <Title size={mq(13)}>Cargar Documentos</Title>
                                            </BtnContent>
                                        }
                                        {item.cEdit === 1 &&
                                            <BtnContent width='47%' onPress={() => navigation.navigate('EditCredit', { cId: item.cId, cpcId: item.creditPayrollCompany.cpcId })}>
                                                <Ionicons name='md-create' size={mq(12)} color={theme.SColor} />
                                                <Title paddingBottom='0' size={mq(12)} numberOfLine={1}>Editar información</Title>
                                            </BtnContent>
                                        }
                                        {(item.cState === 10 && item.cAddInf !== 1) &&
                                            <BtnContent width='47%' onPress={() => navigation.navigate('InformationAdditionalCreditLifeInsurance', { cId: item.cId, cpcId: item.creditPayrollCompany.cpcId })}>
                                                <Ionicons name='md-create' size={mq(12)} color={theme.SColor} />
                                                <Title paddingBottom='0' size={mq(12)} numberOfLine={1}>Información Adicional</Title>
                                            </BtnContent>
                                        }
                                    </Row>
                                </>
                                :
                                (!item.check ?
                                    <ViewMore
                                        onPress={() => handleOpen(item)}
                                    >
                                        <TitleHead
                                            align='right'
                                            size={mq(13)}
                                            color={theme.SFColor}
                                        >
                                            Ver más
                                        </TitleHead>
                                        <MaterialIcons name="keyboard-arrow-down" size={mq(20)} color={theme.SFColor} />
                                    </ViewMore>
                                    :
                                    <>
                                        <Row>
                                            <SubTitle>No. Crédito</SubTitle>
                                            <SubTitle>{item.cConsecutive}</SubTitle>
                                        </Row>
                                        {item.creditFormat?.cfReqValAft !== 0 &&
                                            <Row>
                                                <SubTitle>V. Desembolso</SubTitle>
                                                <SubTitle>$ {numberFormat(item.cReqVal)}</SubTitle>
                                            </Row>
                                        }
                                        {item.creditFormat?.cfRatAft !== 0 &&
                                            <Row>
                                                <SubTitle>Interés EM - A la solicitud</SubTitle>
                                                <SubTitle>{item?.cRatVal.toFixed(2)} %</SubTitle>
                                            </Row>
                                        }
                                        {item.creditFormat?.cfValCouAft !== 0 &&
                                            <Row>
                                                <SubTitle>V. Cuota </SubTitle>
                                                <SubTitle>$ {numberFormat(item.cValCou.toFixed())}</SubTitle>
                                            </Row>
                                        }
                                        <Row>
                                            <BtnContent onPress={() => navigation.navigate('RepaymentPlan', { cId: item.cId, cState: item.cState })}>
                                                <Entypo name='text-document' size={mq(14)} color={theme.PColor} />
                                                <Title size={mq(13)} paddingBottom='0'>Ver Información</Title>
                                            </BtnContent>
                                            <ViewMore
                                                onPress={() => handleOpen(item)}
                                                right={mq(5)}
                                                style={{
                                                    bottom: mq(0)
                                                }}
                                            >
                                                <TitleHead
                                                    align='right'
                                                    size={mq(12)}
                                                    color={theme.SFColor}
                                                >
                                                    Ver menos
                                                </TitleHead>
                                                <MaterialIcons name="keyboard-arrow-up" size={mq(20)} color={theme.SFColor} />
                                            </ViewMore>
                                        </Row>
                                    </>
                                )
                            }
                        </ViewCredit>
                    )}
                />
            }
        </Container>
    )
}

HomeV.propTypes = {
    dataUserById: PropTypes.object,
    data: PropTypes.object,
    handleOpen: PropTypes.func,
    handleState: PropTypes.func,
    paymentProviderData: PropTypes.any,
    handleEnrollment: PropTypes.func,
    recharge: PropTypes.func,
    payroll: PropTypes.object,
}