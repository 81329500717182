import styled from 'styled-components/native'
import { wp } from '../../validation'

export const Container = styled.View`
    flex: 1;
    border-top-left-radius: ${wp(7)}px;
    border-top-right-radius: ${wp(7)}px;
`
export const Form = styled.View`
    padding: ${wp(2.5)}px;
    background-color: white;
    justify-content: center;
`
export const BoxButton = styled.View`
    margin: ${wp(3)}px 0;
    align-items: center;
`