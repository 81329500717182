import PropTypes from 'prop-types';
import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Capa 1"
        viewBox="0 0 381 381"
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fill="#333D78"
                d="M190.5 380.999c105.21 0 190.5-85.29 190.5-190.5S295.71-.001 190.5-.001 0 85.29 0 190.5s85.29 190.5 190.5 190.5Z"
                opacity={0.6}
            />
            <Path
                fill="#000D56"
                d="M190.8 346.6c86.046 0 155.8-69.754 155.8-155.8S276.846 35 190.8 35 35 104.754 35 190.8s69.754 155.8 155.8 155.8Z"
                opacity={0.8}
            />
            <Path
                fill="#010938"
                d="M190.3 310.6c65.887 0 119.3-53.412 119.3-119.3S256.187 72 190.3 72C124.412 72 71 125.412 71 191.3s53.412 119.3 119.3 119.3Z"
            />
            <Path
                fill="#fff"
                fillRule="evenodd"
                d="M267.917 191.418c0 42.795-34.455 77.488-76.958 77.488S114 234.213 114 191.418s34.456-77.488 76.959-77.488 76.958 34.693 76.958 77.488Zm-51.416-61.802c19.169 9.066 29.487 21.018 34.807 32.756h.012c7.333 16.199 5.189 31.527 3.305 39.483a78.092 78.092 0 0 1-1.078 4.032 42.096 42.096 0 0 1-6.255 10.378c-2.571 3.125-9.348 11.129-21.147 13.634-8.815 1.873-15.958-.274-18.494-1.157a34.835 34.835 0 0 1-11.823-7.002s-4.099-3.423-5.663-9.042c-1.268-4.544-.877-10.544 4.727-17.332 12.511-15.161 27.355 6.227 27.355 6.227.853-4.724.782-9.591-.166-14.338-3.187-15.877-16.337-30.513-38.704-34.903-29.511-5.797-53.431 21.412-56.57 25.169-.225.274-.356.441-.356.441 10.485-46.64 47.069-53.415 66.854-53.535 8.02-.047 15.946 1.754 23.196 5.189Z"
                clipRule="evenodd"
            />
            <Path
                fill="#fff"
                d="M183.388 152.364c-29.511-5.797-53.43 21.412-56.569 25.169a32.64 32.64 0 0 0-1.434 3.483c7.665-7.419 13.115-12.119 24.784-19.014 6.054-3.578 14.169-6.811 25.649-6.692 2.737.024 14.276.668 26.324 8.601 12.795 8.421 18.221 18.871 19.951 23.344-3.187-15.877-16.337-30.513-38.705-34.903v.012ZM254.625 201.867a78.092 78.092 0 0 1-1.078 4.032 42.315 42.315 0 0 1-6.255 10.378c-2.571 3.125-9.348 11.129-21.147 13.634-8.815 1.873-15.947-.274-18.494-1.157-5.663-1.968-9.596-5.01-11.823-7.002 0 0-4.099-3.423-5.663-9.042 0 0 5.544 22.569 29.653 21.639 24.109-.931 38.018-24.156 35.281-34.677 0 0-.059.871-.486 2.183l.012.012Z"
            />
            <Path
                fill="#fff"
                d="M236.499 128.96s24.026 24.513 25.222 61.289c.545 16.7-7.191 28.604-13.138 34.903-2.547 2.6-8.663 6.715-12.016 8.17-6.835 2.946-27.399 9.162-45.241-8.54 0 0-17.475-17.475-1.718-34.939a36.58 36.58 0 0 1 1.339-1.383s11.74-9.961 25.957 0c0 0-5.9-12.609-17.901-20.995-9.573-6.692-21.716-8.564-32.971-5.487-8.814 2.41-21.395 8.1-30.399 17.953-7.168 7.682-12.191 17.94-11.456 31.24 2.322 42.013 38.325 57.723 66.758 57.723 18.754 0 41.034-7.406 56.653-24.847 8.245-8.577 14.714-19.384 17.912-32.398 0 0 15.058-49.492-29.025-82.701l.024.012Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h381v381H0z" />
            </ClipPath>
        </Defs>
    </Svg>
);

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
);

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    color2: PropTypes.any,
    color3: PropTypes.any,
    color4: PropTypes.any,
    opacity: PropTypes.any,
    props: PropTypes.any,
};

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};