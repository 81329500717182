import { gql } from '@apollo/client';

export const UPDATE_PAYROLL_DOC_COMPANY = gql`
  mutation UpdatePayrollDocuments_($pcId: ID, $input: IUpdatePayrollDocument!) {
    updatePayrollDocuments_(pcId: $pcId, input: $input) {
      pcId
      payrollDocument {
        pdId
        pcId
        pdPerDoc
        pdBankStatement
        pdFinancialStatements
        pdIncomeTaxReturn
        pdBoardDirectors
        pdTradeBalance
        pdRealEstateRegistration
        pdVehicleOwnershipCard
        pdCommercialReference
        pdBankReference
        pdIncomeWithholdings
        pdCadastreAndValuation
        pdCommercialAppraisal
        pdFroDoc
        pdReaDoc
        pdSerDoc
        pdVoucher
        pdPayRemDoc
        pdBanDoc
        pdConDoc
        pdComActDoc
        pdBudRecDoc
        pdBudCerDoc
        pdRut
        pdComChamber
        pdInstitutionPic
        pdWorkCertificate
        pdAlreadyUpdate
        pdState
      }
    }
  }
`;