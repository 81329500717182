export const Section1 = [
    {
        id: 1,
        title: 'POLÍTICAS DE PROTECCIÓN Y TRATAMIENTO DE DATOS - nameBrand',
        message: 'En cumplimiento de la Ley Estatutaria 1581 de 2012 "Por la cual se dictan disposiciones generales para la protección de datos personales" y el Decreto 1377 de 2013, Decreto 1081 de 2015, Decreto 255 de 2022 y todas las demás disposiciones complementarias que versen sobre todas aquellas actividades que constituyan tratamiento de Datos Personales, para de esta manera garantizar los derechos de la privacidad y Habeas Data de los titulares de información personal. Esta política cobija los Datos Personales, información y bases de datos de Titulares susceptibles de tratamiento, conforme a la finalidad de los acuerdos contractuales o de servicio que se establezcan para la administración de la relación entre las partes. Esta política debe ser aplicada por todos los colaboradores de nameBrand, y rige a partir de su divulgación en todas sus sedes. nameBrand, en su calidad de responsable y encargado del tratamiento de Datos Personales, desarrolla las siguientes Políticas de Tratamiento de Datos y de la Información:',
    },
    {
        id: 2,
        title: '1. INTRODUCCIÓN',
        message: 'nameBrand (en adelante, “GESPROCAP”), identificada con NIT 901.488.181-8, domiciliada en Carrera 54 No. 75 - 57, Local 3, Barranquilla, Atlántico, comprometida con la protección de los Datos Personales de sus clientes, empleados, proveedores y cualquier otra persona cuyos datos sean recolectados, almacenados y tratados, adopta la presente Política de Protección y Tratamiento de Datos Personales en cumplimiento de la Ley 1581 de 2012, el Decreto 1377 de 2013 y las demás normas complementarias.',
    },
    {
        id: 3,
        message: 'Esta política establece los procedimientos y directrices para la recolección, uso, almacenamiento, transferencia y eliminación de los Datos Personales, garantizando la seguridad, confidencialidad y el ejercicio de los derechos de los Titulares.',
    },
    {
        id: 4,
        title: 'MARCO LEGAL',
        message: 'La protección integral de los Datos Personales incluidos en bases de datos o archivos de cualquier fuente de información, garantizando a sus Titulares el ejercicio del derecho constitucional a conocer, actualizar y rectificar su información y demás garantías constitucionales contenidas en los artículos 15 y 20 de la Constitución Política de Colombia.',
    },
    {
        id: 5,
        message: 'La Ley Estatutaria 1581 de 2012 establece las disposiciones generales para la Protección de Datos Personales y dentro de los deberes de los Responsables del Tratamiento, se encuentra el adoptar el Manual Interno de Políticas y Procedimientos que garantice el uso adecuado de la información y gestión de las consultas, peticiones y reclamos.',
    },
    {
        id: 6,
        message: 'A partir del Decreto 1377 de 2013, por el cual se reglamentó parcialmente la Ley Estatutaria 1581 de 2012, se fijaron los parámetros para facilitar la implementación y el cumplimiento de la Ley 1581 de 2012 y se facultan a los responsables y encargados del tratamiento de la información para utilizar documentos físicos, formatos electrónicos, medios verbales o cualquier otra tecnología previstos en la Ley 527 de 1999, siempre y cuando garantice y cumpla con el deber de informar al titular, y así, dar a conocer sus políticas y finalidades del tratamiento de la información.',
    },
    {
        id: 7,
        message: 'De acuerdo con las disposiciones contenidas en el Código Penal, se incurre en el delito de violación de Datos Personales cuando "sin estar facultado para ello, con provecho propio o de un tercero, obtenga, compile, sustraiga, ofrezca, venda, intercambie, envíe, compre, intercepte, divulgue, modifique o emplee códigos personales, datos personales contenidos en ficheros, archivos, bases de datos o medios semejantes".',
    },
    {
        id: 8,
        message: 'A continuación, se relaciona la normatividad legal vigente: ',
    },
    {
        id: 9,
        message: '• Ley 1266 de 2008 – Disposiciones Generales del Habeas Data y las demás normas que la modifiquen, adicionen o complementen.',
    },
    {
        id: 10,
        message: '• Ley 1581 de 2012 - Disposiciones Generales para la Protección de Datos Personales.',
    },
    {
        id: 11,
        message: '• Decreto 1377 de 2013 - Por el cual se reglamenta parcialmente la Ley 1581 de 2012',
    },
    {
        id: 12,
        message: '• Decreto 1074 de 2015 – Sector Comercio, Industria y Turismo.',
    },
    {
        id: 13,
        message: 'Todo lo anterior, es de estricto cumplimiento para todos los funcionarios y terceros involucrados en los procesos de Tratamiento de la información.',
    },
    {
        id: 14,
        title: '2. DEFINICIONES',
        message: 'Para efectos de esta política, se entenderán los siguientes conceptos:',
    },
    {
        id: 15,
        message: '• Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales.',
    },
    {
        id: 16,
        message: '• Aviso de Privacidad: Comunicación verbal o escrita generada por el Responsable, dirigida al Titular para el Tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las Políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los Datos Personales.',
    },
    {
        id: 17,
        message: '• Base de Datos: Conjunto organizado de Datos Personales que sea objeto de Tratamiento',
    },
    {
        id: 18,
        message: '• Dato Personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.',
    },
    {
        id: 19,
        message: '• Dato Privado: Es el dato que por su naturaleza íntima o reservada sólo es relevante para el Titular.',
    },
    {
        id: 20,
        message: '• Dato Público: Es el dato que no sea semiprivado, privado o sensible. Se consideran datos públicos, entre otros, los relativos al estado civil, profesión u oficio y la calidad de comerciante o servidor público.',
    },
    {
        id: 21,
        message: '• Dato Semiprivado: Es aquel que no tiene naturaleza íntima, reservada ni pública, y cuyo conocimiento o divulgación puede interesar no sólo a su Titular sino a cierto sector o grupo de personas o a la sociedad en general, como los datos financieros y crediticios.',
    },
    {
        id: 22,
        message: '• Datos Sensibles: Son aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como el origen racial o étnico, orientación política, convicciones religiosas o filosóficas, pertenencia a sindicatos, salud, vida sexual y datos biométricos.',
    },
    {
        id: 23,
        message: '• Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que realice el Tratamiento de Datos Personales por cuenta del Responsable.',
    },
    {
        id: 24,
        message: '• Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los datos; El Responsable de Tratamiento de Datos Personales, es la persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre el Tratamiento de los datos, decide las finalidades, contenido y uso que otorgará a los Datos Personales que recabe en desarrollo de su actividad económica. El responsable del Tratamiento es quien, define los fines y los medios del Tratamiento de información personal, por lo cual es quien tiene el deber de solicitar y conservar la autorización en la que conste el consentimiento expreso del Titular para el Tratamiento de sus datos, así como informar con claridad la finalidad del mismo.',
    },
    {
        id: 25,
        message: '• Titular: Persona natural cuyos Datos Personales sean objeto de Tratamiento',
    },
    {
        id: 26,
        message: '• Transferencia: Ocurre cuando el Responsable y/o Encargado del Tratamiento de los Datos Personales, ubicado en Colombia, envía la información a un receptor que también es Responsable del Tratamiento y se encuentra dentro o fuera del país.',
    },
    {
        id: 27,
        message: '• Transmisión: Tratamiento de Datos Personales que implica la comunicación de los mismos dentro o fuera del territorio nacional para su Tratamiento por el Encargado.',
    },
    {
        id: 28,
        message: '• Tratamiento: Cualquier operación sobre Datos Personales, como recolección, almacenamiento, uso, circulación o supresión.',
    },
    {
        id: 29,
        message: '• Consecuencia: Resultado de la materialización de un riesgo, expresado cualitativa o cuantitativamente.',
    },
    {
        id: 30,
        message: '• Evaluación del Riesgo: Análisis de la situación para determinar el nivel de riesgo asociado a la posibilidad o probabilidad e impacto.',
    },
    {
        id: 31,
        message: '• Finalidad: La finalidad corresponde a los fines exclusivos para los cuales fue entregada por el Titular. Se deberá informar al Titular del dato de manera clara, suficiente y previa acerca de la  finalidad de la información suministrada. Cualquier utilización diversa, deberá ser autorizada en forma expresa por el Titular.',
    },
    {
        id: 32,
        message: '• Habeas Data: Es el derecho que todo titular de información tiene de conocer, actualizar, rectificar u oponerse a la información concerniente a sus Datos Personales.',
    },
    {
        id: 33,
        message: '• Identificación del peligro: Para reconocer si existe un peligro y definir sus características.',
    },
    {
        id: 34,
        message: '• Incidente: Evento o situación que ocurre en un lugar particular durante un intervalo de tiempo particular',
    },
    {
        id: 35,
        message: '• Medidas de control: Medida(s) implementada(s) con el fin de minimizar la ocurrencia.',
    },
    {
        id: 36,
        message: '• Posibilidad: Se emplea como una descripción cualitativa de la probabilidad o frecuencia.',
    },
    {
        id: 37,
        message: '• Proceso: Conjunto de actividades mutuamente relacionadas o que interactúan, las cuales transforman elementos de entrada en resultados.',
    },
    {
        id: 38,
        message: '• Sistema de información: Conjunto de elementos orientados al Tratamiento y administración de datos e información organizados y listos para su uso posterior, generados para cubrir una necesidad u objetivo',
    },
    {
        id: 39,
        title: '3. PRINCIPIOS PARA EL TRATAMIENTO DE DATOS PERSONALES',
        message: 'nameBrand garantizará el cumplimiento de los siguientes principios:',
    },
    {
        id: 40,
        message: '• Legalidad: El Tratamiento a que se refiere la presente ley es una actividad reglada que debe sujetarse a lo establecido en ella y en las demás disposiciones que la desarrollen.',
    },
    {
        id: 41,
        message: '• Libertad: El Tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los Datos Personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.',
    },
    {
        id: 42,
        message: '• Veracidad o calidad: La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.',
    },
];