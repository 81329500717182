import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 381 381"
        fill="none"
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fill="#035961"
                d="M190.5 380.999c105.21 0 190.5-85.29 190.5-190.5S295.71-.001 190.5-.001 0 85.29 0 190.5s85.29 190.5 190.5 190.5Z"
                opacity={0.6}
            />
            <Path
                fill="#016068"
                d="M190.8 346.6c86.046 0 155.8-69.754 155.8-155.8S276.846 35 190.8 35 35 104.754 35 190.8s69.754 155.8 155.8 155.8Z"
                opacity={0.8}
            />
            <Path
                fill="#014852"
                d="M190.3 310.6c65.888 0 119.3-53.413 119.3-119.3 0-65.888-53.412-119.3-119.3-119.3S71 125.412 71 191.3c0 65.887 53.412 119.3 119.3 119.3Z"
            />
            <Path
                fill="#fff"
                d="M198.404 109a64.01 64.01 0 0 1 23.593 4.502l-51.959 117.693C148.702 220.704 134 198.786 134 173.404c0-35.57 28.834-64.404 64.404-64.404ZM182.603 273.488a63.991 63.991 0 0 1-23.594-4.503l51.959-117.692c21.337 10.49 36.039 32.409 36.039 57.791 0 35.57-28.835 64.404-64.404 64.404Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h381v381H0z" />
            </ClipPath>
        </Defs>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    color2: PropTypes.any,
    color3: PropTypes.any,
    color4: PropTypes.any,
    opacity: PropTypes.any,
    props: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}