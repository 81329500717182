import React from 'react';
import PropTypes from 'prop-types';
import {
    FontAwesome,
    MaterialCommunityIcons,
    Entypo,
    Octicons,
} from '@expo/vector-icons';
/** personal imports */
import { ButtonSubmit } from '../ButtonSubmit';
import { Container, Form, BoxButton } from './styled';
import { ViewPhoto } from '../ViewPhoto';
import theme from '../../../ignore/Constants';
import { hp } from '../../validation';

export const UpdateDocumentV = ({
    data: {
        banner,
        infoCamera,
        values,
        files,
        loading,
        format,
        filePD,
        fileFD,
        fileRD,
        fileSD,
        filePRD,
        fileBD,
        fileCD,
        fileBRD,
        fileCAD,
        fileBCD,
        fileRut,
        fileCC,
        fileIP,
    },
    handleInfo,
    handleSubmit,
    handleButton,
}) => (
    <Container>
        <Form>
            {banner}
            {!!format?.ufFroDoc && (
                <ViewPhoto
                    testID="ce2cb3ae-5e3f-46a4-b938-a755a5b3c00c"
                    nameFile={values?.payrollDocument?.pdFroDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Cédula frontal"
                    uri={files?.pdFroDocA || fileFD}
                    icon={
                        <FontAwesome
                            name="vcard"
                            size={hp(20)}
                            color={theme.onVariantSurfC}
                        />
                    }
                    handleInfo={() => handleInfo(1)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdFroDoc', 'pdFroDocA')}
                />
            )}
            {!!format?.ufReaDoc && (
                <ViewPhoto
                    testID="16b1d799-f054-473a-bae3-acb69dd6f220"
                    nameFile={values?.payrollDocument?.pdReaDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Cédula por detrás"
                    uri={files?.pdReaDocA || fileRD}
                    icon={
                        <MaterialCommunityIcons
                            name="card-bulleted"
                            size={hp(26)}
                            color={theme.onVariantSurfC}
                        />
                    }
                    handleInfo={() => handleInfo(2)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdReaDoc', 'pdReaDocA')}
                />
            )}
            {!!format?.ufPerDoc && (
                <ViewPhoto
                    testID="340787e1-717d-4c2f-af6b-27a249c6c32b"
                    nameFile={values?.payrollDocument?.pdPerDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Selfie con Cédula frontal"
                    uri={files?.pdPerDocA || filePD}
                    icon={
                        <Entypo name="v-card" size={hp(24)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(3)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdPerDoc', 'pdPerDocA')}
                />
            )}
            {!!format?.ufSerDoc && (
                <ViewPhoto
                    testID="c7ab39d3-e2d8-4694-ade7-c55ecbdb79e0"
                    nameFile={values?.payrollDocument?.pdSerDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title={`Servicios Públicos${'\n'} (Luz, Agua o Gas)`}
                    uri={files?.pdSerDocA || fileSD}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(4)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdSerDoc', 'pdSerDocA')}
                />
            )}
            {!!format?.ufPayRemDoc && (
                <ViewPhoto
                    testID="85d83a44-cbb7-4cf4-b9d4-55020d5a7d36"
                    nameFile={values?.payrollDocument?.pdPayRemDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Desprendible de nómina"
                    uri={files?.pdPayRemDocA || filePRD}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(5)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdPayRemDoc', 'pdPayRemDocA')}
                />
            )}
            {!!format?.ufBanDoc && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdBanDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Certificado Bancario"
                    uri={files?.pdBanDocA || fileBD}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(6)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdBanDoc', 'pdBanDocA')}
                />
            )}
            {!!format?.ufConDoc && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdConDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Contrato Laboral"
                    uri={files?.pdConDocA || fileCD}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(7)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdConDoc', 'pdConDocA')}
                />
            )}
            {!!format?.ufComActDoc && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdComActDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Acta de Inicio"
                    uri={files?.pdComActDocA || fileCAD}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(8)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdComActDoc', 'pdComActDocA')}
                />
            )}
            {!!format?.ufBudRecDoc && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdBudRecDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Registro de Presupuesto"
                    uri={files?.pdBudRecDocA || fileBRD}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(9)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdBudRecDoc', 'pdBudRecDocA')}
                />
            )}
            {!!format?.ufBudCerDoc && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdBudCerDoc}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Certificado de Presupuesto"
                    uri={files?.pdBudCerDocA || fileBCD}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(10)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdBudCerDoc', 'pdBudCerDocA')}
                />
            )}
            {!!format?.ufRut && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdRut}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Rut"
                    uri={files?.pdRutA || fileRut}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(11)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdRut', 'pdRutA')}
                />
            )}
            {!!format?.ufComChamber && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdComChamber}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Camara de Comercio"
                    uri={files?.pdComChamberA || fileCC}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(12)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdComChamber', 'pdComChamberA')}
                />
            )}
            {!!format?.ufInstitutionPic && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdInstitutionPic}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Foto de la Institución"
                    uri={files?.pdInstitutionPicA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(13)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdInstitutionPic', 'pdInstitutionPicA')
                    }
                />
            )}
            {!!format?.ufWorkCertificate && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdWorkCertificate}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Certificado laboral"
                    uri={files?.pdWorkCertificateA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(7)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdWorkCertificate', 'pdWorkCertificateA')
                    }
                />
            )}
            {!!format?.ufBankStatement && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdBankStatement}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Flujo de caja - Extracto bancario"
                    uri={files?.pdBankStatementA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(14)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdBankStatement', 'pdBankStatementA')
                    }
                />
            )}
            {!!format?.ufFinancialStatements && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdFinancialStatements}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Estados financieros"
                    uri={files?.pdFinancialStatementsA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(15)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdFinancialStatements', 'pdFinancialStatementsA')
                    }
                />
            )}
            {!!format?.ufIncomeTaxReturn && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdIncomeTaxReturn}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Declaración de renta"
                    uri={files?.pdIncomeTaxReturnA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(16)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdIncomeTaxReturn', 'pdIncomeTaxReturnA')
                    }
                />
            )}
            {!!format?.ufBoardDirectors && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdBoardDirectors}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Acta junta directiva"
                    uri={files?.pdBoardDirectorsA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(17)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdBoardDirectors', 'pdBoardDirectorsA')
                    }
                />
            )}
            {!!format?.ufTradeBalance && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdTradeBalance}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Balance comercial"
                    uri={files?.pdTradeBalanceA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(18)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdTradeBalance', 'pdTradeBalanceA')}
                />
            )}
            {!!format?.ufRealEstateRegistration && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdRealEstateRegistration}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Matricula inmobiliaria"
                    uri={files?.pdRealEstateRegistrationA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(19)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton(
                        'pdRealEstateRegistration',
                        'pdRealEstateRegistrationA'
                    )
                    }
                />
            )}
            {!!format?.ufVehicleOwnershipCard && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdVehicleOwnershipCard}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Tarjeta propiedad vehículo"
                    uri={files?.pdVehicleOwnershipCardA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(20)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdVehicleOwnershipCard', 'pdVehicleOwnershipCardA')
                    }
                />
            )}
            {!!format?.ufCommercialReference && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdCommercialReference}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Referencia comercial"
                    uri={files?.pdCommercialReferenceA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(21)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdCommercialReference', 'pdCommercialReferenceA')
                    }
                />
            )}
            {!!format?.ufBankReference && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdBankReference}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Referencia bancaria"
                    uri={files?.pdBankReferenceA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(22)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdBankReference', 'pdBankReferenceA')
                    }
                />
            )}
            {!!format?.ufIncomeWithholdings && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdIncomeWithholdings}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Certificado de ingresos y retenciones"
                    uri={files?.pdIncomeWithholdingsA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(23)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdIncomeWithholdings', 'pdIncomeWithholdingsA')
                    }
                />
            )}
            {!!format?.ufCadastreAndValuation && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdCadastreAndValuation}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Paz salvo catastro y valorización"
                    uri={files?.pdCadastreAndValuationA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(24)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdCadastreAndValuation', 'pdCadastreAndValuationA')
                    }
                />
            )}
            {!!format?.ufCommercialAppraisal && (
                <ViewPhoto
                    nameFile={values?.payrollDocument?.pdCommercialAppraisal}
                    iconFile={
                        <FontAwesome name="image" size={hp(15)} color={theme.primaryC} />
                    }
                    title="Avaluó comercial"
                    uri={files?.pdCommercialAppraisalA || fileIP}
                    icon={
                        <Octicons name="file" size={hp(20)} color={theme.onVariantSurfC} />
                    }
                    handleInfo={() => handleInfo(25)}
                    titleModal={infoCamera.title}
                    iconModal={infoCamera.icon}
                    steps={infoCamera.steps}
                    handleButton={() => handleButton('pdCommercialAppraisal', 'pdCommercialAppraisalA')
                    }
                />
            )}
            <BoxButton>
                <ButtonSubmit
                    testID="74811d9a-0d37-42ae-8618-8f4e1f96d6a6"
                    onPress={handleSubmit}
                    title="Subir documentos"
                    loading={loading}
                />
            </BoxButton>
        </Form>
    </Container>
);

UpdateDocumentV.propTypes = {
    data: PropTypes.object,
    handleInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleButton: PropTypes.func,
};