import React from 'react'
import Svg, { Path } from 'react-native-svg'
import PropTypes from 'prop-types'
/**
 * Logo SVG
 * @param {any} props Properties Logo Login
 * @returns {ReactElement} Component Logo Login svg
 */
export const LogoLogin = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={130}
        viewBox="0 0 800.51 250.32"
        fill="none"
        {...props}
    >
        <Path
            fill="#000000"
            d="M65.508 12.635H59.15c-10.268 0-18.596 8.327-18.596 18.596v12.735c-4.482.178-8.074 3.863-8.074 8.393V66.18h8.074v63.572h24.954V66.181h3.807c7.287 0 13.195-5.908 13.195-13.194v-9.03H65.508v-7.043a2.05 2.05 0 0 1 2.044-2.045H79.35c7.287 0 13.195-5.908 13.195-13.194v-9.03H65.508v-.01ZM111.927 44.313h11.563v85.43H98.536v-72.04c0-7.389 6.002-13.39 13.391-13.39ZM211.612 56.794c-15.145-22.319-42.059-12.594-51.999-7.933v-4.548h-24.954v72.039c0 7.399 5.992 13.391 13.391 13.391h11.563v-55.31c14.441-13.025 23.397-9.386 28.433-3.797 3.582 3.976 5.411 9.237 5.411 14.591v44.516h24.954V79.638c0-8.111-2.242-16.13-6.79-22.844h-.009Z"
        />
        <Path
            fill="#BCCC56"
            d="M371.492 42.315v.047h-19.524v24.954h19.524v21.672h11.563c7.399 0 13.391-5.992 13.391-13.391V42.315h-24.954Z"
        />
        <Path
            fill="#000000"
            d="M301.947 57.404V44.313h-70.923v21.034h36.554l-37.539 51.436v12.96h59.633c7.399 0 13.391-5.992 13.391-13.391v-8.374h-37.004l35.888-50.574ZM111.008 39.052c8.546 0 15.473-6.928 15.473-15.473 0-8.546-6.927-15.474-15.473-15.474-8.545 0-15.473 6.928-15.473 15.474 0 8.545 6.928 15.473 15.473 15.473ZM456.923 42.362c-8.965 0-17.274 2.963-24.101 7.99v-6.04h-11.563c-7.399 0-13.391 5.993-13.391 13.392V159.17h24.954v-36.207c6.827 5.036 15.126 7.99 24.101 7.99 23.238 0 42.077-19.834 42.077-44.3 0-24.467-18.839-44.3-42.077-44.3v.009Zm-3.245 66.291c-11.516 0-20.856-9.847-20.856-22 0-12.154 9.34-22 20.856-22 11.516 0 20.856 9.846 20.856 22 0 12.153-9.34 22-20.856 22ZM394.711 90.957h-23.21c-2.072 8.665-9.846 15.117-19.149 15.117-10.887 0-19.712-8.825-19.712-19.712 0-9.884 7.287-18.043 16.777-19.468V41.912c-24.072.563-43.409 20.237-43.409 44.45 0 24.213 19.909 44.469 44.469 44.469a44.27 44.27 0 0 0 21.024-5.289v4.201h24.954V90.948h-1.744v.01Z"
        />
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoSecond = ({ color, width, height }) => (
    <LogoLogin
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLogin.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    props: PropTypes.any,
}

LogoSecond.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}