import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { HomeV } from '../../components/Home'
import { Context } from '../../../Context'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_ELECTRONIC_PAYMENT_PROVIDER, GET_USER_BY_ID, USE_ALL_CREDITS, UPDATE_ADO_USER_ENROLLMENT, ADO_PARAMETERS, GET_PAYROLL_COMPANY_BY_ID, CREDIT_ALL_INFORMATION } from './queries'
import { getData, getValueToken } from '../../validation'
// import Constants from 'expo-constants'
import axios from 'axios'
import * as Linking from 'expo-linking'
import { selectClientName } from '../../../ignore/utils'

export const HomeC = ({ navigation, route }) => {
    const { setBalance } = useContext(Context)
    const [uState, setUState] = useState(1)
    const [credits, setCredits] = useState([])
    const [creditsTot, setCreditsTot] = useState([])
    const [disabledCredit, setDisabledCredit] = useState(false)
    const [transactionStatus, setTransactionStatus] = useState({})
    const [dataResponseAdo, setDataResponseAdo] = useState({})
    const [loadingAdo, setLoadingAdo] = useState(false);
    const [adoParameters, setAdoParameters] = useState({});

    const [getAllCredits, { data, loading, called, error }] = useLazyQuery(USE_ALL_CREDITS, { fetchPolicy: 'cache-and-network' })
    const [getElectronicPaymentProvider, { data: paymentProviderData }] = useLazyQuery(GET_ELECTRONIC_PAYMENT_PROVIDER, { fetchPolicy: 'cache-and-network' })
    const [getUserById, { data: dataUserById, called: calledUserById }] = useLazyQuery(GET_USER_BY_ID, { fetchPolicy: 'cache-and-network' })
    const [getPayrollCompany, { data: payroll }] = useLazyQuery(GET_PAYROLL_COMPANY_BY_ID, { fetchPolicy: 'no-cache' })
    const [creditInformation, { data: creditInfo, loading: loadObligation }] = useLazyQuery(CREDIT_ALL_INFORMATION, { fetchPolicy: 'no-cache' })
    const [updateAdoUserEnrollment] = useMutation(UPDATE_ADO_USER_ENROLLMENT)
    const [getAllAdoParameters, { data: dataAdoParameters, called: calledAdoParameters }] =
        useLazyQuery(ADO_PARAMETERS, {
            fetchPolicy: 'cache-and-network',
        });
    // const typeClient = Constants.manifest.extra.client;
    const client = selectClientName().toLowerCase()

    // Activa un evento para buscar de los créditos
    useEffect(() => {
        // crea un evento para consultar de los créditos
        const unsubscribe = navigation.addListener('focus', () => getAllCredits({ variables: { state: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13], order: [['cDatCre', 'DESC']], calculateBalanceToday: true } }))

        // elimina el evento
        return unsubscribe
    }, [navigation, getAllCredits])

    console.log('(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧');
    console.log('(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧');
    console.log('(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧');
    console.log('(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧');
    console.log('(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧');
    console.log('(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧');
    console.log('(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧');
    console.log('(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧(๑•̀ㅂ•́)و✧');

    useEffect(() => {
        getPayrollCompany({ variables: { pcIdeNum: dataUserById?.getUserById?.uIdeNum } })
    }, [dataUserById?.getUserById?.uIdeNum])

    useEffect(() => {
        creditInformation({ variables: { pcId: payroll?.getPayrollCompanyById?.pcId } })
    }, [payroll?.getPayrollCompanyById?.pcId])

    useEffect(() => {
        if (called && data?.credits?.length) {
            // busca el estado del usuario
            getData('tokenAuth').then(x => setUState(getValueToken(x)?.uState))
            let balance = 0

            // filtra todo los créditos activos y los no activos con máximo de 7 dias de rechazo
            const dataC = data.credits.filter(x => {
                if (x.cState ? true : moment(x.cDatCre) >= moment().subtract(7, 'd')) {
                    if (x.cState === 6) balance += x.cValCre - (x.cValCre - (x.cBalance || 0))
                    return true
                }
                return false
            }) || []

            setCredits(dataC)
            setCreditsTot(dataC)
            setBalance(balance)
        }

        // verifica si hay error
        if (error) {
            setCreditsTot([])
            setCredits([])
            setBalance(0)
        }
    }, [data, loading, error])

    useEffect(() => {
        if (data) {
            getElectronicPaymentProvider()
        }
    }, [data])

    useEffect(() => {
        getAllAdoParameters({ variables: { state: [1], onRegister: [1] } })
    }, [client])

    useEffect(() => {
        if (dataAdoParameters?.adoParameters && calledAdoParameters) {
            setAdoParameters(
                dataAdoParameters?.adoParameters?.find(
                    x => x?.brand?.toLowerCase() === client
                )
            );
        }
    }, [dataAdoParameters?.adoParameters, calledAdoParameters]);

    useEffect(() => {
        getUserById({ variables: { uId: route?.params?.uId, state: [1, 2] } })
    }, [route?.params?.uId])

    const getResponseAdo = params => {
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://adocolombia-qa.ado-tech.com/${params?.adoParameter?.projectName}/api/${params?.adoParameter?.projectName}/Validation/${params?.transactionId}?returnImages=false`,
            headers: {
                'Content-Type': 'application/json',
                apiKey: params?.adoParameter?.apiKey,
            },
            data: Number(params?.transactionId),
        };
        axios
            .request(config)
            .then(res => {
                setTransactionStatus(res?.data?.Extras)
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        if (dataUserById?.getUserById && calledUserById && adoParameters?.idAP) {
            const dataUserAdo = dataUserById?.getUserById?.adoUserEnrollment;
            setDataResponseAdo(dataUserAdo);
            getResponseAdo(dataUserAdo);
            if (adoParameters?.idAP) {
                if (dataUserAdo?.adoStateId === 14 || dataUserAdo?.adoStateId === 2) {
                    setDisabledCredit(false)
                } else {
                    setDisabledCredit(true)
                }
            }
        }
    }, [dataUserById?.getUserById, calledUserById, adoParameters])

    const recharge = () => {
        getResponseAdo(dataResponseAdo)
    }

    useEffect(() => {
        if (transactionStatus !== '1' && !loadingAdo && adoParameters?.idAP) {
            updateAdoUserEnrollment({
                variables: {
                    idAue: Number(dataUserById?.getUserById?.adoUserEnrollment?.idAUE),
                    input: {
                        adoStateId: Number(transactionStatus?.IdState)
                    }
                }
            }).then(() => {
                setLoadingAdo(true)
            })
        }
    }, [transactionStatus, loadingAdo, adoParameters])

    const handleEnrollment = () => {
        const dataUserAdo = dataUserById?.getUserById?.adoUserEnrollment;
        const adoParams = dataAdoParameters?.adoParameters?.find(
            x => x?.projectName === adoParameters?.projectName
        );
        const urlCallback = window.location.href;
        const uId = route?.params?.uId;
        // const tiId = route?.params?.tiId;
        // const uIdeNum = route?.params?.uIdeNum;

        // Enrolamiento - Validar
        Linking.openURL(`https://adocolombia-qa.ado-tech.com/${dataResponseAdo ? dataUserAdo?.adoParameter?.projectName : adoParams?.projectName}/validar-persona?callback=${urlCallback}integrationAdo/?params=uId=${uId}/idAP=${dataResponseAdo ? dataUserAdo?.idAP : adoParams?.idAP}/name=${dataResponseAdo ? dataUserAdo?.adoParameter?.projectName : adoParams?.projectName}&key=${dataResponseAdo ? dataUserAdo?.adoParameter?.apiKey : adoParams?.apiKey}&projectName=${dataResponseAdo ? dataUserAdo?.adoParameter?.projectName : adoParams?.projectName}&product=1`)

        // Verificación
        // Linking.openURL(`https://adocolombia-qa.ado-tech.com/${dataResponseAdo ? dataUserAdo?.adoParameter?.projectName : adoParams?.projectName}/verificar-persona?callback=${urlCallback}integrationAdo/?params=uId=${uId}/idAP=${dataResponseAdo ? dataUserAdo?.idAP : adoParams?.idAP}/name=${dataResponseAdo ? dataUserAdo?.adoParameter?.projectName : adoParams?.projectName}/documentType=${tiId}&identificationNumber=${uIdeNum}&key=${dataResponseAdo ? dataUserAdo?.adoParameter?.apiKey : adoParams?.apiKey}&projectName=${dataResponseAdo ? dataUserAdo?.adoParameter?.projectName : adoParams?.projectName}`)
    }

    /**
     * Busca los créditos según el estado
     * @param {any} state estados de los créditos
     * @return {void}
     */
    const handleState = state => {
        if (Array.isArray(state)) setCredits(creditsTot.filter(x => state.find(y => y === x.cState)))
        else setCredits(creditsTot.filter(x => x.cState === state))
    }

    return (
        <HomeV
            dataUserById={dataUserById?.getUserById}
            payroll={payroll?.getPayrollCompanyById}
            data={{ credits, loading: !credits?.length && loading, navigation, uState, disabledCredit, transactionStatus, dataResponseAdo, adoParameters, obligation: creditInfo?.CreditAllInformation?.currentBalance, loadObligation }}
            handleOpen={item => setCredits(credits.map(x => (item.cId === x.cId) ? ({ ...x, check: !x.check }) : x))}
            handleState={handleState}
            paymentProviderData={paymentProviderData?.electronicPaymentProviders}
            recharge={recharge}
            handleEnrollment={handleEnrollment}
        />
    )
}

HomeC.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}