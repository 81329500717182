import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 381 381"
        fill="none"
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fill="#CB1D49"
                d="M190.5 380.999c105.21 0 190.5-85.29 190.5-190.5S295.71-.001 190.5-.001 0 85.289 0 190.499s85.29 190.5 190.5 190.5Z"
                opacity={0.6}
            />
            <Path
                fill="#CB1D49"
                d="M190.8 346.6c86.046 0 155.8-69.754 155.8-155.8S276.846 35 190.8 35 35 104.754 35 190.8s69.754 155.8 155.8 155.8Z"
                opacity={0.6}
            />
            <Path
                fill="#CB1D49"
                d="M190.3 310.6c65.888 0 119.3-53.412 119.3-119.3S256.188 72 190.3 72 71 125.412 71 191.3s53.412 119.3 119.3 119.3Z"
            />
            <Path
                fill="#fff"
                d="M279.076 161.939v14.435c0 1.059-1.283 1.595-2.032.847l-2.61-2.61-51.28 51.28c-20.362 20.362-54.088 19.402-73.209-2.837-15.931-18.556-15.931-46.411.029-64.954 19.134-22.211 52.831-23.142 73.18-2.794l-4.784 4.784c-2.384 2.385-6.123 2.625-8.862.635-15.098-10.95-36.689-9.045-49.529 5.729-11.939 13.745-11.939 34.544.014 48.288 14.154 16.284 38.946 16.934 53.946 1.933l51.28-51.279-2.61-2.61c-.748-.749-.212-2.032.846-2.032h14.436c.65 0 1.185.535 1.185 1.185Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h381v381H0z" />
            </ClipPath>
        </Defs>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    color2: PropTypes.any,
    color3: PropTypes.any,
    color4: PropTypes.any,
    opacity: PropTypes.any,
    props: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}