import { calculatorConsumption } from './calculatorConsumption'
import { calculateRepayment } from './calculateRepayment'

/**
 * calcula el valor del crédito de cartera
 * @param {object} args argumentos
 * @return {object} nuevos argumentos
 */
export function calculateCreditConsumption ({ month, vat, rate, ctRatVal, creditTypeAttributes, gctIntFee, creditTypes, valCouFee }) {
    // calcula el valor del crédito y los atributos
    const data = calculatorConsumption({ attributes: creditTypeAttributes, gctIntFee, creditTypes, valCouFee, mon: month?.mValue, iva: vat?.vPor, ctModule: 2, rPor: rate?.rPor, ratMon: 1 })

    // calcula el plan de amortización
    const repaymentPlan = calculateRepayment({ valCre: data?.valCre, valCou: data?.valCou, fees: data?.fees, rate: ctRatVal ?? rate?.rPor, month: month?.mValue })

    return { ...data, month: month?.mValue, rate: ctRatVal ?? rate?.rPor, repaymentPlan }
}