import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
    FontAwesome,
    MaterialCommunityIcons,
    Entypo,
    Octicons,
    AntDesign,
} from '@expo/vector-icons';
import * as FileSystem from 'expo-file-system';
import Constants from 'expo-constants';
import { USE_ONE_PAYROLL_COMPANY } from '../../pages/RequestCreditData/queries';
import {
    hp,
    putFileS3,
    writeCache,
    validationErrors,
    wp,
    base64ToArrayBuffer,
    extFile,
} from '../../validation';
import theme from '../../../ignore/Constants';
import { isLoggedVar } from '../../apollo/cache';
import { UPDATE_PAYROLL_DOC_COMPANY } from './queries';
import { UpdateDocumentV } from '../../components/UpdateDocument';
import { BannerInfoData } from '../../components/BannerInfoData';
import { ScrollView } from 'react-native-web';
import { ModalCamera } from '../../components/ModalCamera';

export const UpdateDocumentC = ({ navigation, route }) => {
    const [values, setValues] = useState({});
    const [files, setFiles] = useState({});
    const [documents, setDocuments] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorForm, setErrorForm] = useState({
        pdFroDoc: true,
        pdPerDoc: true,
        pdReaDoc: true,
        pdPayRemDoc: true,
    });
    const [infoCamera, setInfoCamera] = useState({});
    const [isVisibleOption, setIsVisibleOption] = useState(false);
    const [optionCamera, setOptionCamera] = useState({});
    const [payrollCompanyMutation, { loadUpd }] = useMutation(
        UPDATE_PAYROLL_DOC_COMPANY
    );
    const [infoBanner] = useState({
        title: 'Actualizar documentos Personales',
        icon: <AntDesign name="idcard" size={wp(7)} color={theme.backgroundC} />,
        titleModal: 'Documentos Personales',
        iconModal: (
            <AntDesign name="idcard" size={wp(50)} color={theme.backgroundC} />
        ),
        steps: [
            {
                text: 'En esta sección deberás actualizar tus documentos toma fotos y sube los documentos solicitados.',
            },
            { text: '1. Cédula frontal.' },
            { text: '2. Cédula por detrás.' },
            { text: '3. Selfie con Cédula frontal.' },
            { text: '4. Desprendible de nómina.' },
        ],
    });

    const refScroll = useRef();

    const handleButton = (type, typeA) => {
        setOptionCamera({ screen: 'UpdateDocument', type, typeA });
        setIsVisibleOption(!isVisibleOption);
    };

    const dataUser = {
        cId: route?.params?.cId,
        pcId: route?.params?.pcId,
    };
    const format = { ufFroDoc: 1, ufPerDoc: 1, ufReaDoc: 1, ufPayRemDoc: 1 };

    // Actualiza las fotos tomadas
    useEffect(() => {
        if (documents?.name) {
            setValues({
                payrollDocument: {
                    ...values?.payrollDocument,
                    [documents.name]: `${new Date().getTime()}1.${documents.ext}`,
                },
            });
            setFiles({
                ...files,
                [documents.name]: documents.file,
                [documents.nameA]: documents.base64,
            });
            setErrorForm({ ...errorForm, [documents.name]: !documents.file });
        }
    }, [documents]);

    // acción de capturar foto
    const onPicture = async ({ event, type, typeA, nameFile }) => {
        const ext = nameFile ? extFile(nameFile) : 'jpeg';

        let file, base64;
        if (Constants.platform.web) {
            base64 = event;
            file = base64ToArrayBuffer(
                event.substring(event.indexOf('base64,') + 7, event.length)
            );
        } else {
            base64 = await FileSystem.readAsStringAsync(event, {
                encoding: FileSystem.EncodingType.Base64,
            });
            file = base64ToArrayBuffer(base64);
            base64 = event;
        }
        setDocuments({ name: type, nameA: typeA, file, ext, base64 });
    };

    useEffect(() => {
        if (route.params?.event) {
            onPicture({
                event: route.params.event,
                type: route.params.type,
                typeA: route.params.typeA,
                nameFile: route.params.nameFile,
            });
        } else {
            setDocuments({});
        }
    }, [route.params]);

    // coloca la información de la cámara
    const handleInfo = type => {
        let stepsInf = [];
        if (type === 1) {
            stepsInf = [
                { text: '2. Enfoque la parte frontal de su documento de identidad .' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 2) {
            stepsInf = [
                { text: '2. Enfoque la parte reversa de su documento de identidad.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 3) {
            stepsInf = [
                {
                    text: '2. Enfoque su rostro sosteniendo el documento de identidad a la misma altura de la cabeza.',
                },
                { text: '3. No utilizar accesorios.' },
            ];
        } else if (type === 4) {
            stepsInf = [
                { text: '2. Tome la foto de un servicio público (Agua, luz o gas).' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 5) {
            stepsInf = [
                { text: '2. Tome la foto del desprendible de nómina.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 6) {
            stepsInf = [
                { text: '2. Tome la foto del certificado bancario.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 7) {
            stepsInf = [
                { text: '2. Tome la foto del contrato laboral.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 8) {
            stepsInf = [
                { text: '2. Tome la foto del acta de inicio.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 9) {
            stepsInf = [
                { text: '2. Tome la foto del registro de presupuesto.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 10) {
            stepsInf = [
                { text: '2. Tome la foto del certificado de presupuesto.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 11) {
            stepsInf = [
                { text: '2. Tome la foto del RUT.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 12) {
            stepsInf = [
                { text: '2. Tome la foto del Cámara de Comercio.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 13) {
            stepsInf = [
                { text: '2. Tome la foto del Foto del Establecimiento.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 14) {
            stepsInf = [
                {
                    text: '2. Anexe foto o archivo de flujo de caja - Extracto bancario.',
                },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 15) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de estados financieros.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 16) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de declaración de renta.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 17) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de acta junta directiva.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 18) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de balance comercial.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 19) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de matricula inmobiliaria.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 20) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de tarjeta propiedad vehículo.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 21) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de referencia comercial.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 22) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de referencia bancaria.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 23) {
            stepsInf = [
                {
                    text: '2. Anexe foto o archivo de certificado de ingresos y retenciones.',
                },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 24) {
            stepsInf = [
                {
                    text: '2. Anexe foto o archivo de paz salvo catastro y valorización.',
                },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        } else if (type === 25) {
            stepsInf = [
                { text: '2. Anexe foto o archivo de avaluó comercial.' },
                { text: '3. La imagen debe ser visible y legible.' },
            ];
        }

        setInfoCamera({
            title: 'Documentos personales',
            icon:
        type === 1 ? (
            <FontAwesome name="vcard" size={hp(20)} color={theme.backgroundC} />
        ) : type === 2 ? (
            <MaterialCommunityIcons
                name="card-bulleted"
                size={hp(26)}
                color={theme.backgroundC}
            />
        ) : type === 3 ? (
            <Entypo name="v-card" size={hp(24)} color={theme.backgroundC} />
        ) : (
            <Octicons name="file" size={hp(20)} color={theme.backgroundC} />
        ),
            steps: [
                { text: 'Sigues las instrucciones para capturar la foto.' },
                { text: '1. Capture dentro de los marcos señalados.' },
                ...stepsInf,
            ],
        });
    };

    const handleSubmit = async () => {
    /** verifica si los campos están rellenos */
        if (validationErrors(errorForm)) {
            return isLoggedVar({
                ...isLoggedVar(),
                message: 'Por favor rellene todo los campos',
            });
        }
        setLoading(true);
        files.pdPerDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdPerDoc}`,
          files.pdPerDoc
      ));
        files.pdFroDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdFroDoc}`,
          files.pdFroDoc
      ));
        files.pdReaDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdReaDoc}`,
          files.pdReaDoc
      ));
        files.pdSerDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdSerDoc}`,
          files.pdSerDoc
      ));
        files.pdPayRemDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdPayRemDoc}`,
          files.pdPayRemDoc
      ));
        files.pdBanDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBanDoc}`,
          files.pdBanDoc
      ));
        files.pdConDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdConDoc}`,
          files.pdConDoc
      ));
        files.pdComActDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdComActDoc}`,
          files.pdComActDoc
      ));
        files.pdBudRecDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBudRecDoc}`,
          files.pdBudRecDoc
      ));
        files.pdBudCerDoc &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBudCerDoc}`,
          files.pdBudCerDoc
      ));
        files.pdRut &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdRut}`,
          files.pdRut
      ));
        files.pdComChamber &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdComChamber}`,
          files.pdComChamber
      ));
        files.pdInstitutionPic &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdInstitutionPic}`,
          files.pdInstitutionPic
      ));
        files.pdWorkCertificate &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdWorkCertificate}`,
          files.pdWorkCertificate
      ));
        files.pdBankStatement &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBankStatement}`,
          files.pdBankStatement
      ));
        files.pdFinancialStatements &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdFinancialStatements}`,
          files.pdFinancialStatements
      ));
        files.pdIncomeTaxReturn &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdIncomeTaxReturn}`,
          files.pdIncomeTaxReturn
      ));
        files.pdBoardDirectors &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBoardDirectors}`,
          files.pdBoardDirectors
      ));
        files.pdTradeBalance &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdTradeBalance}`,
          files.pdTradeBalance
      ));
        files.pdRealEstateRegistration &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdRealEstateRegistration}`,
          files.pdRealEstateRegistration
      ));
        files.pdVehicleOwnershipCard &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdVehicleOwnershipCard}`,
          files.pdVehicleOwnershipCard
      ));
        files.pdCommercialReference &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdCommercialReference}`,
          files.pdCommercialReference
      ));
        files.pdBankReference &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdBankReference}`,
          files.pdBankReference
      ));
        files.pdIncomeWithholdings &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdIncomeWithholdings}`,
          files.pdIncomeWithholdings
      ));
        files.pdCadastreAndValuation &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdCadastreAndValuation}`,
          files.pdCadastreAndValuation
      ));
        files.pdCommercialAppraisal &&
      (await putFileS3(
          `companys/${dataUser.cId}/payroll/${dataUser.pcId}/documents/${values.payrollDocument?.pdCommercialAppraisal}`,
          files.pdCommercialAppraisal
      ));

        const { data, error } = await payrollCompanyMutation({
            variables: {
                pcId: dataUser.pcId,
                input: {
                    ...values,
                    pcId: dataUser.pcId,
                    payrollDocument: { ...values?.payrollDocument, pdAlreadyUpdate: 0 },
                },
            },
            update(store, { data: { setOrUpdatePayrollCompany } }) {
                writeCache({
                    store,
                    query: USE_ONE_PAYROLL_COMPANY,
                    name: 'getPayrollCompanyById',
                    dataNew: setOrUpdatePayrollCompany,
                    variables: { state: [0, 1, 2, 3] },
                    typeName: 'PayrollCompany',
                });
            },
        }).catch(e => ({ error: e }));

        // verifica si hay error
        if (error) return isLoggedVar({ ...isLoggedVar(), message: error.message });

        /** resultado */
        if (data?.updatePayrollDocuments_) {
            refScroll?.current &&
        refScroll?.current.scrollTo({ x: 0, y: 0, animated: true });
            navigation.navigate('Home');
        }
    };

    return (
        <ScrollView
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            ref={refScroll}
        >
            <ModalCamera
                navigation={navigation}
                data={optionCamera}
                handleClose={() => setIsVisibleOption(false)}
                isVisible={isVisibleOption}
            />
            <UpdateDocumentV
                data={{
                    banner: (
                        <BannerInfoData
                            title={infoBanner.title}
                            icon={infoBanner.icon}
                            titleModal={infoBanner.titleModal}
                            iconModal={infoBanner.iconModal}
                            steps={infoBanner.steps}
                        />
                    ),
                    files,
                    values,
                    loading: loading || loadUpd,
                    infoCamera,
                    format,
                }}
                handleButton={handleButton}
                handleSubmit={handleSubmit}
                handleInfo={handleInfo}
                refScroll={refScroll}
            />
        </ScrollView>
    );
};

UpdateDocumentC.propTypes = {
    route: PropTypes.object,
    documents: PropTypes.object,
    dataUser: PropTypes.object,
    format: PropTypes.object,
    navigation: PropTypes.object,
};