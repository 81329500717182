import React, { Component } from 'react'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './src/apollo/client'
import { Image } from 'react-native'
import { ThemeProvider } from 'styled-components/native'
import * as Updates from 'expo-updates'
import * as Font from 'expo-font'
import Index from './index'
import PFontLight from './assets/fonts/Montserrat-Light.ttf'
import PFontBold from './assets/fonts/Montserrat-Bold.ttf'
import PFontMedium from './assets/fonts/Montserrat-Medium.ttf'
import PFontRegular from './assets/fonts/Montserrat-Regular.ttf'
import Constants from 'expo-constants'
import Context from './Context'
import theme from './ignore/Constants'
import image from './assets/img/image'
import { NoAuthorize } from './src/components/NoAuthorize'
import { selectClientName } from './ignore/utils'
const Splash = image('Splash')
const client = selectClientName();

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = { fontLoaded: false, stateClient: true }
    }

    async componentDidMount() {
        // busca actualizaciones en expo pendientes
        const update = await Updates.checkForUpdateAsync().catch(x => x)
        if (update?.isAvailable) {
            await Updates.fetchUpdateAsync().catch(x => x)
            await Updates.reloadAsync().catch(x => x)
            return
        }

        /** Agrega los tipos de fuente a expo */
        await Font.loadAsync({ PFontLight, PFontBold, PFontMedium, PFontRegular })
        /** activa intl si es android */
        if (Constants.platform.android) {
            // eslint-disable-next-line global-require
            require('intl')
            // eslint-disable-next-line global-require
            require('intl/locale-data/jsonp/de-DE')
        }
        this.setState({ fontLoaded: true })

        const response = await fetch(`https://acceso.mili.com.co/api/security/validateLicense/${client}`, {
            headers: {
                token: 'MILI',
                'api-key':
                    '004b7ce8a5cb0e30c6666365fd0174fa2a44f6d358dcfc93e7a8d420e1965a8e',
            },
        });
        const data = await response.json();
        this.setState({ stateClient: data?.access });
    }

    render() {
        if (this.state.fontLoaded) {
            return (
                <ThemeProvider theme={theme.PTheme}>
                    <ApolloProvider client={apolloClient}>
                        <Context.Provider>
                            {this.state.stateClient === true ? <Index /> : <NoAuthorize />}
                        </Context.Provider>
                    </ApolloProvider>
                </ThemeProvider>
            )
        }
        else return <Image source={Splash} style={{ height: '100%', width: '100%' }} />
    }
}