import { allTerms } from './Section';

export const TerCon = ({
    name,
    nameBrand
}) => {
    const allTermsConditions = allTerms?.map(term => ({
        ...term,
        title: term.title?.replace('nameBrand', nameBrand),
        message: term.message?.replace('nameBrand', nameBrand),
    }));

    return [
        ...allTermsConditions,
        {
            id: 109,
            title:
                '5.3. Finalidades aplicables a los candidatos a una vacante respecto de los candidatos a una vacante',
            message: 'El Responsable informa que su información personal será tratada durante el proceso de reclutamiento y selección, para llevar a cabo pruebas de desempeño, competencias y habilidades, visitas domiciliarias, evaluaciones psicotécnicas, psicosociales, análisis de referencias, el análisis de historia laboral y las demás que se estimen necesarias para identificar la conveniencia de la contratación. Su información se conservará en la base de datos denominada “candidatos”, que se encuentra en archivo físico y automatizado, por un periodo máximo de seis (6) meses y una vez finalizado este tiempo se procederá a destruir o eliminar la carpeta o archivo.',
        },
        {
            id: 110,
            title:
                '5.4. Finalidades aplicables al tratamiento de la información de proveedores y aliados Estratégicos',
            message: 'Los Datos Personales de los proveedores, entendida como Información de contacto, nombres, ocupación tipo y número de identificación, teléfono celular y local, dirección de domicilio, correo electrónico, actividad económica, información financiera y tributaria, cuenta bancaria, operaciones internacionales, descripción de productos en moneda extranjera, referencias de clientes y personales, información SARLAFT, se utilizarán de acuerdo con las finalidades que se relacionan a continuación:',
        },
        {
            id: 111,
            message:
                '5.4.1. Incluirlo en nuestra Base de Datos como proveedores y/o aliados estratégicos.',
        },
        {
            id: 112,
            message:
                '5.4.2. Incorporar sus Datos Personales en el contrato comercial, documentos que resulten necesarios para gestionar la relación comercial y obligaciones derivadas de la misma que se encuentren a cargo del Responsable.',
        },
        {
            id: 113,
            message:
                '5.4.3. Administrar los Datos Personales para cumplir con las obligaciones que se imponen al responsable en su condición de contratante.',
        },
        {
            id: 114,
            message:
                '5.4.4. Dentro del proceso de selección, se podrá revisar y evaluar aspectos financieros, técnicos, idoneidad y de cualquier otra índole que permita al responsable identificar la capacidad de cumplimiento del proveedor.',
        },
        {
            id: 115,
            message: '5.4.5. Efectuar pedidos y solicitudes de servicios y productos al proveedor.',
        },
        {
            id: 116,
            message: '5.4.6. Reportar información tributaria referente a las compras.',
        },
        {
            id: 117,
            message: '5.4.7. Desarrollar esquemas de ofertas y planes comerciales.',
        },
        {
            id: 118,
            message: '5.4.8. Gestionar los diferentes procesos de pagos, facturas y cuentas de cobro.',
        },
        {
            id: 119,
            message:
                '5.4.9. Evaluar la prestación del servicio prestado y realizar estudios estadísticos y análisis de mercado.',
        },
        {
            id: 120,
            message: '5.4.10. Brindar asistencia e información de interés general y comercial.',
        },
        {
            id: 121,
            message:
                '5.4.11. Utilizar, en el evento que sea necesario, los Datos Personales de los empleados y contratistas del proveedor con el fin de establecer controles de acceso a la infraestructura lógica o física del Responsable.',
        },
        {
            id: 122,
            message:
                '5.4.12. Aportar información a las autoridades competentes y administrativas en razón de sus funciones, cuando así lo soliciten, y se encuentren legitimadas para acceder a la misma.',
        },
        {
            id: 123,
            message:
                '5.4.13. Informar sobre cambios sustanciales en la Política de Tratamiento de Datos Personales.',
        },
        {
            id: 124,
            message:
                '5.4.14. Responder a las peticiones, consultas, reclamos y/o quejas que realicen los titulares de información de tipo personal a través de cualquiera de los canales habilitados para dicho efecto.',
        },
        {
            id: 125,
            message:
                '5.4.15. Transferir o transmitir la información personal a terceros, cuya gestión garantice el correcto funcionamiento de la operación del Responsable, ya sea por una prestación de servicios como agencias de transporte, de aduanas, servicios de tecnología e infraestructura, abogados, entre otros, así se encuentren en países diferentes a Colombia sin importar si cumplen o no los requisitos mínimos adecuados sobre protección de datos personales establecidos por la ley colombiana para su Tratamiento.',
        },
        {
            id: 126,
            message:
                '5.4.16. Para efectos del tratamiento de datos personales de los terceros que el proveedor suministre, ya sea de sus empleados o contratistas, el responsable asume que su proveedor cuenta con la debida autorización de los titulares para ser entregados y tratados de acuerdo con las finalidades contempladas en la presente política de tratamiento de datos personales.',
        },
        {
            id: 127,
            title:
                '5.5. Finalidades aplicables al tratamiento de información personal de los prospectos comerciales y clientes',
            message: `Los Datos Personales de los prospectos comerciales y clientes de ${name} dependerán del tipo de cliente involucrado. Los datos captados corresponden a: nombres, apellidos, tipo y número de identificación, teléfono celular y local, dirección de domicilio y correo electrónico, como lugar y fecha de nacimiento, edad, sexo, estado civil, personas a cargo, nivel de estudio, tipo de vivienda, ocupación y profesión, datos del cónyuge y beneficiarios (nombres, apellidos, tipo y número de identificación, dirección, teléfono celular y local), información financiera, tributaria y/o laboral, operaciones en el exterior referencias personales y familiares, información SARLAFT, entre otros. Estos se utilizarán de acuerdo con las finalidades que se relacionan a continuación:`,
        },
        {
            id: 128,
            message: '5.5.1. Registrarlo en la Base de Datos como prospecto comercial y cliente.',
        },
        {
            id: 129,
            message:
                '5.5.2. Verificar, validar, confirmar y actualizar la información e identidad del prospecto comercial y cliente.',
        },
        {
            id: 130,
            message:
                '5.5.3. Conocer el comportamiento financiero, comercial, crediticio, y nivel de endeudamiento del prospecto comercial y cliente.',
        },
        {
            id: 131,
            message:
                '5.5.4. Establecer, mantener, actualizar y terminar una relación contractual, para la cual se utilizarán sus datos para generar comunicaciones efectivas.',
        },
        {
            id: 132,
            message:
                '5.5.5. Efectuar una adecuada prestación de los servicios financieros de colocación, captación y gestión de cobro. La gestión de cobro y recuperación de cartera se podrá efectuar directamente por la entidad o a través de un tercero contratado para tal función, razón por la cual, el cliente aprueba que su información personal sea compartida con el mencionado tercero.',
        },
        {
            id: 133,
            message:
                '5.5.6. Establecer un canal de comunicación efectiva con el prospecto comercial y cliente, cuyo contacto puede efectuarse a través de llamada telefónica, mensaje de texto, correo electrónico, redes sociales, aplicaciones de mensajería instantánea (WhatsApp, entre otras), correspondencia física, entre otros.',
        },
        {
            id: 134,
            message:
                '5.5.7. Grabar llamadas telefónicas, guardar las comunicaciones y, en general, dejar constancia de los mensajes que se intercambien en desarrollo del contrato.',
        },
        {
            id: 135,
            message: '5.5.8. Efectuar estudios estadísticos y análisis de mercado.',
        },
        {
            id: 136,
            message:
                '5.5.9. Destruir los documentos entregados en caso de que la solicitud de financiación sea negada o en caso de que sea aprobada y no aceptada.',
        },
        {
            id: 137,
            message:
                '5.5.10. Informar sobre el estado de los servicios contratados, modificaciones, novedades y realización de encuestas de satisfacción del servicio.',
        },
        {
            id: 138,
            message:
                '5.5.11. Responder a solicitudes o requerimientos de información de nuestros productos y servicios.',
        },
        {
            id: 139,
            message:
                '5.5.12. Realizar análisis estadísticos de tendencias, hábitos de consumo y comportamientos del consumidor.',
        },
        {
            id: 140,
            message: '5.5.13. Gestionar actividades de servicio al cliente y postventa.',
        },
        {
            id: 141,
            message:
                '5.5.14. Ofrecer los productos o servicios de la entidad, así como los servicios y productos de las entidades con las cuales cuenten con una alianza vigente, a través de cualquier medio conocido o por conocerse, ya sea directamente o a través de un tercero encargado para tal efecto.',
        },
        {
            id: 142,
            message:
                '5.5.15. Informar sobre cambios sustanciales en la Política de Tratamiento de Datos Personales.',
        },
        {
            id: 143,
            message:
                '5.5.16. Responder a las peticiones, consultas, reclamos y/o quejas que realicen los titulares de información de tipo personal a través de cualquiera de los canales habilitados para dicho efecto.',
        },
        {
            id: 144,
            message:
                '5.5.17. Transferir o transmitir la información personal a terceros, cuya gestión garantice el correcto funcionamiento de la operación del Responsable, ya sea por una prestación de servicios como agencias de transporte, servicios de tecnología e infraestructura, abogados, entre otros, así se encuentren en países diferentes a Colombia sin importar si cumplen o no los requisitos mínimos adecuados sobre protección de datos personales establecidos por la ley colombiana para su Tratamiento.',
        },
        {
            id: 145,
            message:
                '5.5.18. El Responsable entiende que los datos personales de los terceros, tanto como beneficiarios, cónyuge, referencias personales y familiares, que el cliente y prospecto comercial suministre cuentan con la autorización de los titulares para ser entregados y tratados de acuerdo con las finalidades contempladas en la presente Política de Protección de Datos Personales.',
        },
        {
            id: 146,
            title: 'Uso de Cookies y Web Beacons.',
            message: `${nameBrand}, podrá hacer uso de cookies, web beacons y otras tecnologías similares, en sus páginas web y en los dispositivos electrónicos utilizados para acceder a éstas, con el fin de conocer la procedencia, actividades o preferencias de clientes al navegar en la web, incrementar la funcionalidad, verificar que los clientes y/o usuarios cumplan con los criterios requeridos para procesar sus solicitudes y para adaptar los productos y/o servicios a las necesidades de los usuarios, obteniendo la siguiente información:`,
        },
        {
            id: 147,
            message: '• El tipo de navegador.',
        },
        {
            id: 148,
            message: '• Sistema operativo.',
        },
        {
            id: 149,
            message: '• Idioma del dispositivo.',
        },
        {
            id: 150,
            message: '• Sitios web visitados.',
        },
        {
            id: 151,
            message: '• Vínculos accedidos.',
        },
        {
            id: 152,
            message: '• Dirección IP.',
        },
        {
            id: 153,
            message: `• Sitio web visitado antes de entrar a la página de ${nameBrand}.`,
        },
        {
            id: 154,
            message: `• Duración de navegación en la página web de ${nameBrand}.`,
        },
        {
            id: 155,
            message: '• Transacciones realizadas.',
        },
        {
            id: 156,
            message: '• Fecha y hora.',
        },
        {
            id: 157,
            message: 'Las cookies, web beacons y otras tecnologías similares pueden ser deshabilitadas y eliminadas por el cliente y/o usuario cuando lo desee. Para este efecto, el cliente y/o usuario puede consultar y/o solicitar la ayuda del navegador de Internet que utilice.',
        },
        {
            id: 158,
            title: '6. DERECHOS DEL TITULAR',
            message: 'Los Titulares de información personal podrán ejercer el derecho de Habeas Data ante el Responsable de Tratamiento, es decir, podrán acceder, conocer, rectificar u oponerse al tratamiento de sus datos personales, razón por la cual procedemos a describir el contenido y detalle de cada uno de los derechos.',
        },
        {
            id: 159,
            message:
                'a. Derecho de Conocimiento: El Titular de la información tiene derecho de conocer si sus datos personales han sido sometidos a cualquier forma de tratamiento y podrá ejercer el derecho de conocer el origen de sus datos, forma de autorización y si los mismos han sido transmitidos o transferidos a terceros, así como a conocer la identificación de esos terceros.',
        },
        {
            id: 160,
            message:
                'b. Derecho de Actualización: El Titular de la información tiene el derecho de actualizar la información conservada.',
        },
        {
            id: 161,
            message:
                'c. Derechos de Rectificación: El Titular de la información podrá comprobar frente al responsable del Tratamiento de la información la exactitud y veracidad, y ante alguna discrepancia, por ser inexactos, incompletos o errados, podrá solicitar la rectificación de su información personal.',
        },
        {
            id: 162,
            message:
                'd. Derecho de Oposición: El Titular de la información personal podrá oponerse al Tratamiento de su información personal, cuando su uso resulte excesivo o inadecuado, para lo cual deberá indicar los datos que se deben cancelar, con el objetivo de generar un bloqueo de sus datos o cancelación definitiva del dato o cese del Tratamiento del uso no autorizado. Los datos podrán ser conservados durante los tiempos previstos en la normatividad aplicable y la misma no podrá ser suprimida completamente cuando el titular tenga el deber legal o contractual de permanecer en la base de datos del responsable del tratamiento.',
        },
        {
            id: 163,
            title: '7. RESPONSABILIDADES',
        },
        {
            id: 163,
            title: '7.1. Responsabilidad de los Titulares de la Información.',
            message: `Los Titulares tendrán la responsabilidad de informar sobre todos los cambios que se realicen en sus Datos Personales para que ${nameBrand} se asegure de que la información de la que dispone está actualizada y, en consecuencia, modificará o eliminará los Datos Personales de acuerdo con la actualización que realicen los Titulares.`,
        },
        {
            id: 164,
            title: `7.2. Responsabilidad del ${nameBrand}.`,
            message: `En el momento en que ${nameBrand} proceda con la eliminación de Datos Personales, se realizará un registro, donde se documente dicho proceso. Lo anterior se hará con el fin de llevar el control de los Datos Personales de los cuales se realiza el tratamiento para los diferentes procesos internos de ${nameBrand} de acuerdo con las finalidades para las cuales los Titulares han dado su autorización. `,
        },
        {
            id: 165,
            title: `7.2. Responsabilidad del ${nameBrand}.`,
            message: `En el momento en que ${nameBrand} proceda con la eliminación de Datos Personales, se realizará un registro, donde se documente dicho proceso. Lo anterior se hará con el fin de llevar el control de los Datos Personales de los cuales se realiza el tratamiento para los diferentes procesos internos de ${nameBrand} de acuerdo con las finalidades para las cuales los Titulares han dado su autorización.`,
        },
        {
            id: 166,
            message: `En los casos que los Datos Personales de los miembros de la Junta Directiva, accionistas, colaboradores y a quienes represente, proveedores, visitantes, clientes y prospectos sean obtenidos por ${nameBrand} dada su naturaleza de datos públicos, ésta información será utilizada sólo para los propósitos aquí señalados, y no procederá a vender, licenciar, transmitir o divulgar la información salvo que:`,
        },
        {
            id: 167,
            message: 'I. Se autorice expresamente a hacerlo ',
        },
        {
            id: 168,
            message: `II. Sea necesario para permitir a nuestros terceros y/o proveedores para la prestación de servicios. ${nameBrand} remitirá la información financiera y crediticia pertinente a Operadores de Información y/o Agencias de Información Comercial, de conformidad con la Ley 1266 de 2008. `,
        },
        {
            id: 169,
            message: `${nameBrand} remitirá la información a las autoridades gubernamentales o judiciales que repose en sus bases de datos, cuando medie un acto administrativo o una providencia judicial que así lo disponga.`,
        },
        {
            id: 170,
            message: `${nameBrand} dará cumplimiento a las responsabilidades establecidas en el artículo 17 de la Ley 1581 de 2012:`,
        },
        {
            id: 171,
            message:
                'a) Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de Habeas Data.',
        },
        {
            id: 172,
            message:
                'b) Solicitar y conservar, en las condiciones previstas en la citada ley, copia de la respectiva autorización otorgada por el Titular.',
        },
        {
            id: 173,
            message:
                'c) Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.',
        },
        {
            id: 174,
            message:
                'd) Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.',
        },
        {
            id: 175,
            message:
                'e) Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.',
        },
        {
            id: 176,
            message:
                'f) Actualizar la información, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a éste se mantenga actualizada.',
        },
        {
            id: 177,
            message:
                'g) Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento.',
        },
        {
            id: 178,
            message:
                'h) Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad con lo previsto en la citada ley.',
        },
        {
            id: 179,
            message:
                'i) Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.',
        },
        {
            id: 180,
            message:
                'j) Tramitar las consultas y reclamos formulados en los términos señalados en la citada ley.',
        },
        {
            id: 181,
            message:
                'k) Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la citada ley y, en especial, para la atención de consultas y reclamos.',
        },
        {
            id: 182,
            message:
                'l) Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.',
        },
        {
            id: 183,
            message: 'm) Informar a solicitud del Titular sobre el uso dado a sus datos.',
        },
        {
            id: 184,
            message:
                'n) Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.',
        },
        {
            id: 185,
            message:
                'o) Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.',
        },
        {
            id: 186,
            message:
                'p) Realizar el registro de las Bases de Datos identificadas en el Registro Nacional de Bases de Datos – RNBD, en cumplimiento a lo establecido en el artículo 25 de la Ley 1581 de 2012 y el artículo 2.2.2.26.1.2 del Decreto 1074 de 2015.',
        },
        {
            id: 187,
            message:
                'q) Realizar el Reporte de Novedades teniendo en cuenta los reclamos realizados por los Titulares ante los Responsables y/o Encargados, así como también deberán reportarse los incidentes de seguridad que afecten las Bases de Datos administradas por los Responsables y/o Encargados, en cumplimiento a lo establecido en la Circular Externa 003 de 2018 capitulo 2.1 literal F.',
        },
        {
            id: 188,
            message:
                'r) Realizar la actualización de la información contenida en el Registro Nacional de Bases de Datos – RNBD, en cumplimiento a lo establecido en la Circular Externa 003 de 2018 capítulo 2.3. información lógica y digital.',
        },
        {
            id: 189,
            message: `s) La información Biométrica no podrá ser compartida por ${nameBrand} para complementar otras Bases de Datos, ni para fines distintos a los expresados en la presente autorización.`,
        },
        {
            id: 190,
            title: '7.3. Responsabilidad de los Encargados de la Información.',
            message: `${nameBrand}, además de ser el Responsable de la protección de Datos Personales, tiene la calidad de Encargado del Tratamiento frente a las Bases de Datos creadas por ${nameBrand}. `,
        },
        {
            id: 191,
            message: 'Son responsabilidades de los Encargados del Tratamiento de la información lo establecido en el artículo 17 de la Ley 1581 de 2012:',
        },
        {
            id: 192,
            message:
                'a. Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.',
        },
        {
            id: 193,
            message:
                'b. Solicitar y conservar, en las condiciones previstas en la citada ley, copia de la respectiva autorización otorgada por el Titular.',
        },
        {
            id: 194,
            message:
                'c. Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada.',
        },
        {
            id: 195,
            message:
                'd. Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.',
        },
        {
            id: 196,
            message:
                'e. Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.',
        },
        {
            id: 197,
            message:
                'f. Actualizar la información, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a éste se mantenga actualizada.',
        },
        {
            id: 198,
            message:
                'g. Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento.',
        },
        {
            id: 199,
            message:
                'h. Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad con lo previsto en la citada ley.',
        },
        {
            id: 200,
            message:
                'i. Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.',
        },
        {
            id: 201,
            message:
                'j. Tramitar las consultas y reclamos formulados en los términos señalados en la citada ley.',
        },
        {
            id: 202,
            message:
                'k. Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la citada ley y, en especial, para la atención de consultas y reclamos.',
        },
        {
            id: 203,
            message:
                'l. Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo.',
        },
        {
            id: 204,
            message: 'm. Informar a solicitud del Titular sobre el uso dado a sus datos.',
        },
        {
            id: 205,
            message:
                'n. Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.',
        },
        {
            id: 206,
            message:
                'o. Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.',
        },
        {
            id: 207,
            message:
                'p. Asegurar que la información Biométrica consultada para la apertura de productos no podrá complementar otras bases de datos, ni para fines distintos a los expresados en la presente autorización.',
        },
        {
            id: 208,
            message: `q. Actualizar la información reportada por ${nameBrand} dentro de los 5 días hábiles siguientes a su recibo.`,
        },
        {
            id: 209,
            message:
                'r. Informar a la SIC cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración o tratamiento de la información o datos personales de los titulares.',
        },
        {
            id: 210,
            title: '7.4. Prohibiciones.',
        },
        {
            id: 211,
            message:
                'a. No podrán realizarse Tratamientos de Datos Personales incompatibles con la finalidad autorizada por el titular o por la ley, a menos que se cuente con el consentimiento inequívoco de los Titulares.',
        },
        {
            id: 212,
            message:
                'b. No podrá efectuarse el Tratamiento de datos parciales, inexactos, incompletos, fraccionados o que induzcan a error, o de aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.',
        },
        {
            id: 213,
            message:
                'c. Los datos personales, salvo la información pública, no podrán estar disponibles en internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados.',
        },
        {
            id: 214,
            message:
                'd. Se prohíbe el tratamiento de datos sensibles excepto cuando el Titular haya dado su autorización explícita a dicho tratamiento, cuando el tratamiento de los datos sea necesario para salvaguardar el interés vital del titular y este se encuentre física o jurídicamente incapacitado (en estos eventos, los representantes legales deberán otorgar su autorización), cuando el tratamiento sea efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación, ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o sindical, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón de su finalidad, cuando el tratamiento se refiera a datos que el Titular haya hecho manifiestamente públicos o sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, cuando el tratamiento tenga una finalidad histórica, estadística o científica (En este evento deberán adoptarse las medidas conducentes a la supresión de identidad de los Titulares).',
        },
        {
            id: 215,
            message:
                'e. No podrán transferirse datos personales a países que no cumplan con las características de puerto seguro, excepto cuando se trate del Intercambio de datos de carácter médico, cuando así lo exija el tratamiento del titular por razones de salud o higiene pública, se trate de transferencias bancarias o bursátiles, se trate de transferencias acordadas en el marco de tratados internacionales, de transferencias necesarias para la ejecución de un contrato entre el titular y el responsable del tratamiento, o para la ejecución de medidas precontractuales siempre y cuando se cuente con la autorización del titular, transferencias necesarias o legalmente exigidas para la salvaguardia del interés público, o para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.',
        },
        {
            id: 216,
            title: '8. PERIODO DE CONSERVACIÓN Y DESTRUCCIÓN DE LA INFORMACIÓN',
            message: `La información que sea objeto de uso para todos los procesos de ${nameBrand} deberá conservarse de acuerdo con lo establecido en el artículo 96 del Estatuto Orgánico del Sistema Financiero (EOSF) y el Código de Comercio en el ARTICULO 60. Conservación de los Libros y Papeles Contables - Reproducción Exacta. Vencido este tiempo, la información podrá ser eliminada de las bases de datos de ${nameBrand}, de acuerdo con los procesos internos definidos como seguros.`,
        },
        {
            id: 217,
            title:
                '9. PROCEDIMIENTO PARA EL EJERCICIO DE LOS DERECHOS DE LOS TITULARES DE LA INFORMACIÓN',
        },
        {
            id: 218,
            title: '9.1. Procedimientos para Consultas y Reclamos',
        },
        {
            id: 219,
            title: '9.1.1. Consultas',
            message: 'Las consultas serán atendidas en un término máximo de diez (10) días hábiles contados a partir de la fecha de su recibo. Cuando no sea posible atender la consulta dentro de este plazo, se informará al interesado antes del vencimiento del término, expresando los motivos de la demora y la fecha en que se atenderá, la cual no podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer plazo.',
        },
        {
            id: 220,
            title: '9.1.2. Reclamos',
            message: 'Para presentar la solicitud, los clientes deberán incluir y/o suministrar por lo menos la información: ',
        },
        {
            id: 221,
            message: '• Nombre completo y apellidos;',
        },
        {
            id: 222,
            message: '• Número de identificación;',
        },
        {
            id: 223,
            message: '• Datos de contacto (dirección física y/o electrónica y teléfonos de contacto);',
        },
        {
            id: 224,
            message: '• Medios para recibir respuesta a su solicitud;',
        },
        {
            id: 225,
            message:
                '• Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer (acceder a la información, conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, suprimir, eliminar y/o inactivar); y',
        },
        {
            id: 226,
            message: '• Firma (si aplica).',
        },
        {
            id: 227,
            message: `El tiempo máximo previsto por la ley para resolver una reclamación es de quince (15) días hábiles, contado a partir del día siguiente a la fecha del radicado. Cuando no fuere posible atender el reclamo dentro de dicho término, ${nameBrand} informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.`,
        },
        {
            id: 228,
            message: 'Una vez cumplidos los términos señalados por la Ley 1581 de 2012, Decreto 1377 de 2013, Ley 1266 de 2008 y las demás normas que la modifiquen, adicionen o complementen, el Titular al que se deniegue, total o parcialmente, el ejercicio de los derechos de acceso, actualización, rectificación, revocación, eliminación y/o inactivación, podrá poner su caso en conocimiento de la Superintendencia de Industria y Comercio – Delegatura para la Protección de Datos Personales.',
        },
        {
            id: 229,
            message: `La solicitud de supresión de información y la revocatoria de autorización no procederán cuando el Titular tenga un deber legal o contractual de permanecer en la base de datos de ${nameBrand}.`,
        },
        {
            id: 230,
            message: `Para más información, ${nameBrand} pone a disposición de los Titulares sus canales de atención mencionados anteriormente.`,
        },
        {
            id: 231,
            title: '9.2. Canales de atención',
            message: 'Para consultas y reclamos, los Titulares pueden comunicarse a través de los siguientes canales:',
        },
        {
            id: 232,
            message: 'Correo electrónico: administracion@gesprocap.com.co',
        },
        {
            id: 233,
            message: 'Dirección física: Carrera 54 No. 75 - 57, Local 3, Barranquilla, Atlántico',
        },
        {
            id: 234,
            message: 'Teléfono: +57 3205732667',
        },
        {
            id: 235,
            title: '10. AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES',
            message: 'El Tratamiento de Datos Personales se refiere a cualquier operación o procedimiento físico o automatizado involucrado dentro del ciclo de vida del dato, en los que se incluyen actividades como captura, creación, uso, almacenamiento, custodia, mantenimiento, modificación, acceso, transmisión, transferencia, disociación, supresión y eliminación.',
        },
        {
            id: 236,
            message:
                '• Datos personales captados por el responsable: Los datos personales captados pueden incluir sin limitarse a datos de identificación, contacto, ubicación, financieros, académicos, laborales, socioeconómicos, biométricos, imágenes, fotografías, salud, entre otros, los cuales han sido suministrados directa o indirectamente por el titular conforme a las finalidades identificadas y autorizadas para facilitar el intercambio de prestaciones, bienes y servicios, así como con el fin de cumplir las obligaciones legales, comerciales, civiles y laborales que se encuentren a cargo del responsable.',
        },
        {
            id: 237,
            message:
                '• Transmisión y transferencia de información personal: El Responsable del Tratamiento, manifiesta que solo compartirá información personal con terceros, cuando el Titular así lo autorice, otorgando su consentimiento para la transferencia o transmisión, cuando sea requerida por autoridad judicial, autorizada por la ley o cuando la misma sea necesaria para garantizar la continuidad de su operación, como por ejemplo, proveedores de servicios tecnológicos, de mensajería, de seguridad, entre otros, independiente de que el proveedor del servicio se encuentre en países diferentes a Colombia sin importar si cumplen o no los requisitos mínimos adecuados sobre protección de datos personales establecidos por la ley para su tratamiento. No obstante, cabe mencionar que el Responsable ha advertido a dichos terceros sobre la necesidad de proteger la información personal con medidas de seguridad apropiadas, que les prohíbe el uso de la información para fines propios y se solicita que no se divulgue la información personal a otros terceros sin la debida autorización.',
        },
        {
            id: 238,
            message:
                '• Tratamiento de datos sensibles: Cuando el Tratamiento de información involucre datos de tipo sensible, es decir, datos que afectan la intimidad del titular o cuyo uso indebido puede generar su discriminación, como aquellos que revelen información de salud física y mental, vida sexual, datos biométricos, origen étnico, orientación política, convicciones religiosas, afiliación sindical e intereses políticos, el responsable del tratamiento garantiza que su tratamiento se efectuará conforme a las limitaciones legales y asegurando que: (i) siempre se cuenta con la autorización previa, informada, explícita y expresa para efectuar dicho tratamiento; (ii) que el titular haya sido informado del carácter facultativo de la autorización; (iii) no condicionará ninguna actividad a solicitud de este tipo de datos; y (iv) cuando su tratamiento esté reglamentado explícitamente en la ley. Cuando se requiera realizar Tratamiento de Datos Personales de menores de edad y adolescentes, siempre respetará sus derechos fundamentales y el interés superior de los niños, niñas y adolescentes, respetando sus derechos fundamentales y en la medida de lo posible se realizará dicho Tratamiento teniendo en cuenta su (i) madurez; (ii) autonomía; (iii) capacidad para entender la finalidad del tratamiento; y (iv) comprensión de las consecuencias que conlleva el tratamiento.',
        },
        {
            id: 239,
            message: `• Tratamiento de datos en sistema de videovigilancia: El tratamiento de imágenes con fines de seguridad y vigilancia tiene como objetivo garantizar la seguridad de los bienes y personas que se encuentran en las instalaciones de ${nameBrand}. Las imágenes serán captadas a través de sistemas de videovigilancia y se entenderá que la autorización para dicha captación ha sido otorgada por el Titular de la información personal mediante conducta inequívoca debido a que previamente se ha comunicado al ingresar a ${nameBrand} que sus imágenes serán tomadas, conservadas y usadas conforme a lo establecido en el aviso de privacidad publicado y conociendo tal situación, el titular al ingresar a ${nameBrand}, autoriza el Tratamiento de sus Datos Personales. Se advierte que cualquier uso diferente será notificado de manera previa al titular de Datos Personales.`,
        },
        {
            id: 240,
            message:
                '• Pertinencia y custodia de los datos personales captados: El Responsable indica que el Tratamiento de Datos Personales se efectuará durante el tiempo que sea pertinente, necesario y razonable según las finalidades que justifican el tratamiento, atendiendo a los aspectos administrativos, contables, fiscales, jurídicos e históricos de la información, por lo cual la información será conservada cuando así se requiera para el cumplimiento de una obligación legal o contractual. Una vez cumplidas las finalidades del Tratamiento se procederá a la supresión de la información a menos que exista una normatividad vigente que disponga lo contrario.',
        },
        {
            id: 241,
            title: '11. REVOCATORIA, SUPRESIÓN, ELIMINACIÓN Y/O INACTIVACIÓN DE LA AUTORIZACIÓN',
            message: 'Los Titulares de los Datos Personales pueden solicitar la revocación, supresión, eliminación y/o inactivación de la autorización del uso de Datos Personales otorgada a favor del Responsable del Tratamiento sobre sus datos personales, en cualquier momento, mediante la presentación de un reclamo de acuerdo con el procedimiento establecido en la Ley 1581 de 2012, Decreto 1377 de 2013, Ley 1266 de 2008 y las demás normas que la modifiquen, adicionen o complementen.',
        },
        {
            id: 242,
            message: `La solicitud de revocación, supresión, eliminación y/o inactivación de la autorización del uso de Datos Personales no procederá en los casos que el cliente de ${nameBrand}, tenga un deber legal o contractual de permanecer en la Base de Datos. `,
        },
        {
            id: 243,
            message: 'Es importante tener en cuenta que la solicitud de revocación, supresión, eliminación y/o inactivación de la autorización del uso de Datos Personales podrá solicitarse sobre la totalidad o parte de los datos, que comprenden la autorización otorgada, de acuerdo a las Finalidades definidas en la Política de Protección de Datos Personales, más no respecto de los datos regulados al amparo de la Ley 1581 de 2012, Decreto 1377 de 2013, Ley 1266 de 2008 y las demás normas que la modifiquen, adicionen o complementen, así como tampoco aquellos casos en los que el cliente tenga un deber legal o contractual de permanecer en la Base de Datos.',
        },
        {
            id: 244,
            title: '12. SEGURIDAD DE LA INFORMACIÓN',
            message: `${nameBrand} adoptará medidas de seguridad técnicas y administrativas para proteger la información contra accesos no autorizados, pérdida, alteración o divulgación indebida.`,
        },
        {
            id: 245,
            title: '13. MODIFICACIONES A LA POLÍTICA',
            message: `${nameBrand} se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente documento, con el propósito de cumplir nuevas actualizaciones de carácter normativo, de políticas internas o nuevos requerimientos para la prestación u ofrecimiento de sus servicios y/o productos.`,
        },
        {
            id: 246,
            message: `Estas modificaciones serán informadas a nuestros miembros de la Junta Directiva, accionistas, colaboradores, proveedores, visitantes, clientes y prospectos, a través de uno de los siguientes medios: anuncios visibles en las oficinas de ${nameBrand}, en la página web o vía correo electrónico a la dirección que repose en la base de datos ${nameBrand} o a través de los diferentes mecanismos de comunicación con los que cuenta ${nameBrand}.`,
        },
        {
            id: 247,
            title: '14. ACEPTACIÓN DE LAS POLÍTICAS DE TRATAMIENTO DE LA INFORMACIÓN PERSONAL',
            message: `Los miembros de la Junta Directiva, accionistas, colaboradores, proveedores, visitantes, clientes y prospectos de ${nameBrand} aceptan, el tratamiento de sus Datos Personales conforme los términos del presente documento.`,
        },
        {
            id: 248,
            title: '15. VIGENCIA DE LA POLÍTICA Y DE LOS DATOS PERSONALES',
            message: 'Esta política rige a partir de su publicación y permanecerá vigente hasta su modificación o derogación.',
        },
        {
            id: 249,
            message: 'Los Datos Personales serán tratados mientras sean necesarios para cumplir con las finalidades descritas y por el tiempo requerido por las normas aplicables',
        },
        {
            id: 250,
            title: '16. CONTACTO',
            message: 'Para cualquier inquietud o solicitud relacionada con la protección de Datos Personales, puede contactarnos en:',
        },
        {
            id: 251,
            message: 'Correo electrónico: administracion@gesprocap.com.co',
        },
        {
            id: 252,
            message: 'Dirección física: Carrera 54 No. 75 - 57, Local 3, Barranquilla, Atlántico',
        },
        {
            id: 253,
            message: 'Teléfono: +57 3205732667',
        },
        {
            id: 254,
            message: 'Esta política rige a partir de la fecha y permanece vigente hasta su modificación o revocación.',
        },
    ];
}