import PropTypes from 'prop-types'
import * as React from 'react'
import Svg, { ClipPath, Defs, G, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: style */

const LogoLoginPass = ({ props }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 392 392"
        fill="none"
        {...props}
    >
        <G clipPath="url(#a)">
            <Path
                fill="#C8D461"
                d="M190.5 380.999c105.21 0 190.5-85.29 190.5-190.5S295.71-.001 190.5-.001 0 85.29 0 190.5s85.29 190.5 190.5 190.5Z"
                opacity={0.6}
            />
            <Path
                fill="#9AA93E"
                d="M190.8 346.6c86.046 0 155.8-69.754 155.8-155.8S276.846 35 190.8 35 35 104.754 35 190.8s69.754 155.8 155.8 155.8Z"
                opacity={0.7}
            />
            <Path
                fill="#9AA93E"
                d="M190.3 310.6c65.888 0 119.3-53.412 119.3-119.3S256.188 72 190.3 72 71 125.412 71 191.3s53.412 119.3 119.3 119.3Z"
            />
            <Path
                fill="#fff"
                d="M185.385 143v.096H145v51.328h40.385V239h23.916C224.605 239 237 226.675 237 211.456V143h-51.615Z"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path fill="#fff" d="M0 0h381v381H0z" />
            </ClipPath>
        </Defs>
    </Svg>
)

/**
 * Component Logo
 * @param {any} Properties Logo Component
 * @returns {ReactElement} Logo component
 */
export const LogoLogin = ({ color, width, height }) => (
    <LogoLoginPass
        width={width}
        height={height}
        color={color}
        idOne={`${Math.random() * 10000}`}
        idTwo={`${Math.random() * 10000}`}
        idsvOne={`${Math.random() * 10000}`}
        idsvTwo={`${Math.random() * 10000}`}
        idsvThree={`${Math.random() * 10000}`}
        idsvFour={`${Math.random() * 10000}`}
        idsvFive={`${Math.random() * 10000}`}
        idsvSix={`${Math.random() * 10000}`}
        idsvSeven={`${Math.random() * 10000}`}
        idsvEight={`${Math.random() * 10000}`}
        idsvNine={`${Math.random() * 10000}`}
        idsvTen={`${Math.random() * 10000}`}
        idsvElev={`${Math.random() * 10000}`}
        idsvTwelve={`${Math.random() * 10000}`}
    />
)

LogoLoginPass.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    style: PropTypes.any,
    size: PropTypes.any,
    color2: PropTypes.any,
    color3: PropTypes.any,
    color4: PropTypes.any,
    opacity: PropTypes.any,
    props: PropTypes.any
}

LogoLogin.propTypes = {
    color: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}