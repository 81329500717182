export const Section2 = [
    {
        id: 43,
        message: '• Transparencia: En el Tratamiento debe garantizarse el derecho del Titular a obtener del Responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.',
    },
    {
        id: 44,
        message: '• Seguridad: Se adoptarán medidas técnicas, humanas y administrativas para proteger la información.',
    },
    {
        id: 45,
        message: '• Confidencialidad: El Tratamiento se realizará garantizando la reserva de la información.',
    },
    {
        id: 46,
        message: '• Acceso y circulación restringida: El Tratamiento se sujeta a los límites que se derivan de la naturaleza de los Datos Personales, de las disposiciones de la ley y la Constitución. En este sentido, el Tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la ley.',
    },
    {
        id: 47,
        title: 'DATOS RECOLECTADOS',
        message: 'nameBrand podrá recolectar, almacenar y tratar los siguientes tipos de Datos Personales:',
    },
    {
        id: 48,
        message: '• Datos de identificación (nombre, cédula, firma, fotografía, etc.).',
    },
    {
        id: 49,
        message: '• Datos de contacto (correo electrónico, teléfono, dirección física etc.).',
    },
    {
        id: 50,
        message: '• Datos financieros (ingresos, historial crediticio, información transaccional etc.).',
    },
    {
        id: 51,
        message: '• Datos laborales (experiencia profesional, referencias laborales, afiliación a seguridad social etc.).',
    },
    {
        id: 52,
        message: '• Datos biométricos (huellas dactilares, reconocimiento facial, voz, etc.).',
    },
    {
        id: 53,
        message: '• Datos de navegación y ubicación (dirección IP, cookies, geolocalización etc.).',
    },
    {
        id: 54,
        message: '• Datos sensibles, cuyo tratamiento estará sujeto a regulaciones específicas y solo se recolectarán con el consentimiento expreso del titular.',
    },
    {
        id: 55,
        message: 'En relación con estos datos, por tratarse de datos considerados sensibles, su manejo se realizará de conformidad con lo establecido en el artículo 6º de la Ley 1581 de 2012, el cual establece textualmente lo siguiente: “Artículo 6°. Tratamiento de datos sensibles. Se prohíbe el Tratamiento de datos sensibles, excepto cuando:',
    },

    {
        id: 56,
        message: 'a. El Titular haya dado su autorización explícita a dicho Tratamiento, salvo en los casos que por ley no sea requerido el otorgamiento de dicha autorización;',
    },
    {
        id: 57,
        message: 'b. El Tratamiento sea necesario para salvaguardar el interés vital del Titular y este se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización; ',
    },
    {
        id: 58,
        message: 'c. El Tratamiento sea efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación, ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o sindical, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del Titular;',
    },
    {
        id: 59,
        message: 'd. El Tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial;',
    },
    {
        id: 60,
        message: 'e. El Tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán adoptarse las medidas conducentes a la supresión de identidad de los Titulares.”',
    },
    {
        id: 61,
        title: '4.1. Responsable del tratamiento de la información',
        message: '• Razón Social: nameBrand ',
    },
    {
        id: 62,
        message: 'Sigla: GESPROCAP S.A.S.',
    },
    {
        id: 63,
        message: '• NIT: 901.488.181 - 8',
    },
    {
        id: 64,
        message: '• Dirección domicilio principal: CR 54 No 75 - 57 LO 3',
    },
    {
        id: 65,
        message: '• Municipio: Barranquilla - Atlántico',
    },
    {
        id: 66,
        message: '• Correo electrónico: administracion@gesprocap.com.co',
    },
    {
        id: 67,
        message: '• Teléfono: 3205732667',
    },
    {
        id: 68,
        title: '5. FINALIDADES DEL TRATAMIENTO DE DATOS',
        message: 'El Responsable del Tratamiento, efectuará el Tratamiento de Datos Personales para garantizar el desarrollo de su objeto social y al giro ordinario de sus actividades, considerando las finalidades Tratamiento de los Datos Personales específicas para cada uno de los grupos de interés involucrados en el tratamiento, tales como, ACCIONISTAS, MIEMBROS DE JUNTA DIRECTIVA, COLABORADORES, PRACTICANTES Y APRENDICES, CANDIDATOS A UNA VACANTE, PROVEEDORES, PROSPECTOS COMERCIALES Y CLIENTES, advirtiendo que la información personal que de estos reposen en sus bases de datos tendrán una vigencia igual al periodo en que se mantenga la finalidad del Tratamiento en cada Base de Datos.',
    },
    {
        id: 69,
        title: '5.1. Finalidades del tratamiento de información personal de accionistas',
        message: 'El Responsable informa a los accionistas de la entidad que el Tratamiento de sus Datos Personales, relacionados con información de contacto, nombres, ocupación, tipo y número de identificación, teléfono celular y local, dirección de domicilio, correo electrónico, actividad económica, información financiera y  tributaria, incluida información de cuenta bancaria e información SARLAFT, será tratada conforme a lo establecido en la normatividad vigente y las finalidades que aplican al tratamiento de su información personal son las siguientes:',
    },
    {
        id: 70,
        message:
            '5.1.1. Incluirlo en la Base de Datos y libro de registro de accionistas como accionista de la entidad.',
    },
    {
        id: 71,
        message:
            '5.1.2. Informar, organizar, controlar y acreditar las actividades pertinentes en su calidad de accionista.',
    },
    {
        id: 72,
        message: '5.1.3. Permitir el ejercicio de los derechos y deberes como accionistas.',
    },
    {
        id: 73,
        message:
            '5.1.4. Efectuar el pago de dividendos conforme a su participación económica y accionaria.',
    },
    {
        id: 74,
        message:
            '5.1.5. Cumplir decisiones judiciales, administrativas y legales relacionadas con su calidad de accionistas.',
    },
    {
        id: 75,
        message: '5.1.6. Realizar estudios estadísticos, y análisis de mercado.',
    },
    {
        id: 76,
        message:
            '5.1.7. Efectuar las verificaciones pertinentes dentro del programa de Administración de Riesgos de Lavado de Activos y Financiación del Terrorismo.',
    },
    {
        id: 77,
        message:
            '5.1.8. Informar sobre cambios sustanciales en la Política de Tratamiento de Datos Personales.',
    },
    {
        id: 78,
        message:
            '5.1.9. Responder a las peticiones, consultas, reclamos y/o quejas que realicen los titulares de información de tipo personal a través de cualquiera de los canales habilitados para dicho efecto.',
    },
    {
        id: 79,
        message:
            '5.1.10. Finalidades del tratamiento de información personal de miembros de Junta Directiva.',
    },
    {
        id: 80,
        message:
            '5.1.11. El responsable informa a los miembros de junta directiva de la entidad que el Tratamiento de sus Datos Personales, relacionados con información de contacto, nombres, ocupación, tipo y número de identificación, teléfono celular y local, dirección de domicilio, correo electrónico, actividad económica, información financiera y tributaria, incluida información de cuenta bancaria e información SARLAFT, será tratada conforme a lo establecido en la normatividad vigente y las finalidades que aplican al tratamiento de su información personal son las siguientes:',
    },
    {
        id: 81,
        message:
            '5.1.11.1. Incluirlo en la Base de Datos como miembro de junta directiva de la entidad.',
    },
    {
        id: 82,
        message:
            '5.1.11.2. Informar, organizar, controlar y acreditar las actividades pertinentes en su calidad de miembro de junta directiva.',
    },
    {
        id: 83,
        message:
            '5.1.11.3. Permitir el ejercicio de los derechos y deberes como miembro de junta directiva.',
    },
    {
        id: 84,
        message: '5.1.11.4. Efectuar el pago de honorarios.',
    },
    {
        id: 85,
        message:
            '5.1.11.5. Cumplir decisiones judiciales, administrativas y legales relacionadas con su calidad de miembro de junta directiva.',
    },
    {
        id: 86,
        message: '5.1.11.6. Realizar estudios estadísticos, y análisis de mercado.',
    },
    {
        id: 87,
        message:
            '5.1.11.7. Efectuar las verificaciones pertinentes dentro del programa de Administración de Riesgos de Lavado de Activos y Financiación del Terrorismo.',
    },
    {
        id: 88,
        message:
            '5.1.11.8. Informar sobre cambios sustanciales en la Política de Tratamiento de Datos Personales.',
    },
    {
        id: 89,
        message:
            '5.1.11.9. Responder a las peticiones, consultas, reclamos y/o quejas que realicen los Titulares de información de tipo personal a través de cualquiera de los canales habilitados para dicho efecto.',
    },
    {
        id: 90,
        message:
            '5.1.11.10. Transferir o transmitir la información personal a terceros, cuya gestión garantice el correcto funcionamiento de la operación del responsable, ya sea por una prestación de servicios como servicios de tecnología e infraestructura, entre otros, así se encuentren en países diferentes a Colombia sin importar si cumplen o no los requisitos mínimos adecuados sobre protección de datos personales establecidos por la ley colombiana para su tratamiento.',
    },
    {
        id: 91,
        title:
            '5.2. Finalidades aplicables a las relaciones con colaboradores, practicantes y aprendices',
        message: 'El Responsable tratará la información personal de los colaboradores, directos e indirectos, practicantes y aprendices, entendida como, nombres, tipo y número de identificación, teléfono celular y local, dirección de domicilio, correo electrónico, lugar y fecha de nacimiento, edad, sexo, estado civil, personas a cargo, nivel de estudio, ocupación y profesión, grupo sanguíneo, información médica, información financiera, información del núcleo familiar y/o personas dependientes, e información SARLAFT, de acuerdo con las siguientes finalidades:',
    },
    {
        id: 92,
        message:
            '5.2.1. Incluirlos en la Base de Datos de colaboradores (directos e indirectos), practicantes y aprendices.',
    },
    {
        id: 93,
        message:
            '5.2.2. Incorporar sus Datos Personales en el contrato laboral, de aprendizaje o práctica, los documentos que resulten necesarios para gestionar la relación laboral y obligaciones derivadas de la misma que se encuentren a cargo del Responsable.',
    },
    {
        id: 94,
        message:
            '5.2.3. Administrar los Datos Personales para cumplir con las obligaciones que se imponen al responsable en su condición de empleador, entendidas como gestión de prestaciones sociales, contribuciones, retenciones, impuestos, controversias laborales, así como adelantar las afiliaciones al sistema de seguridad social, fondos de pensiones, administradoras de riesgos laborales, cajas de compensación familiar y demás entidades en las que el empleado haya autorizado el tratamiento de su información previamente.',
    },
    {
        id: 95,
        message:
            '5.2.4. Utilizar los Datos Personales del titular y los de su núcleo familiar para realizar trámites de afiliación a las entidades promotoras de salud, cajas de compensación familiar, administradoras de riesgo laboral, y demás necesarias para cumplir con su deber como empleador.',
    },
    {
        id: 96,
        message:
            '5.2.5. Garantizar una correcta ejecución de lo establecido en el Reglamento Interno de Trabajo.',
    },
    {
        id: 97,
        message:
            '5.2.6. Gestionar sus datos personales para garantizar una correcta asignación de las herramientas de trabajo en las que se incluyen herramientas tecnológicas como correo electrónico, teléfono móvil, acceso a sistemas operativos, aplicaciones, entre otros.',
    },
    {
        id: 98,
        message:
            '5.2.7. Controlar y fiscalizar el desarrollo y rendimiento de las actividades laborales en el trabajo asignado, a través del monitoreo y control de geolocalización e imágenes de videovigilancia.',
    },
    {
        id: 99,
        message:
            '5.2.8. Efectuar el correspondiente pago de la nómina, incluida la realización de los descuentos para pagos a terceros que el titular previamente haya autorizado.',
    },
    {
        id: 100,
        message:
            '5.2.9. Responder solicitudes del titular sobre expedición de certificados, constancias y demás documentos solicitados en virtud del vínculo laboral.',
    },
    {
        id: 101,
        message:
            '5.2.10. Archivar y custodiar de manera adecuada la información laboral activa e histórica de los empleados.',
    },
    {
        id: 102,
        message: '5.2.11. Enviar comunicaciones internas relacionadas o no con su vinculación laboral.',
    },
    {
        id: 103,
        message:
            '5.2.12. Promover su participación en programas desarrollados para aumentar el bienestar y buen clima laboral.',
    },
    {
        id: 104,
        message:
            '5.2.13. Establecer y gestionar el proceso de reclutamiento, selección y contratación.',
    },
    {
        id: 105,
        message:
            '5.2.14. Gestionar pruebas psicotécnicas, de desempeño de competencias y habilidades, visitas domiciliarias, evaluaciones psicosociales, pruebas de polígrafo y las que se consideren como pertinentes para adelantar el proceso de selección y la contratación de la persona como empleado del Responsable.',
    },
    {
        id: 106,
        message:
            '5.2.15. Informar sobre cambios sustanciales en la Política de Tratamiento de Datos Personales.',
    },
    {
        id: 107,
        message:
            '5.2.16. Responder a las peticiones, consultas, reclamos y/o quejas que realicen los Titulares e información de tipo personal a través de cualquiera de los canales habilitados para dicho efecto. Transferir o transmitir la información personal a terceros, cuya gestión garantice el correcto funcionamiento de la operación del Responsable, ya sea por una prestación de servicios como servicios de tecnología e infraestructura, abogados laboralistas, entre otros, así se encuentren en países diferentes a Colombia sin importar si cumplen o no los requisitos mínimos adecuados sobre protección de datos personales establecidos por la ley colombiana para su tratamiento.',
    },
    {
        id: 108,
        message:
            '5.2.17. Para efectos del Tratamiento de Datos Personales de los terceros que el colaborador suministre, como beneficiarios, cónyuge, referencias personales y comerciales, el responsable asume que el colaborador cuenta con la debida autorización de los titulares para ser entregados y tratados de acuerdo con las finalidades contempladas en la presente Política de Tratamiento de Datos Personales.',
    },
];