export const selectClientName = () => {
    const client = window.location.hostname;
    if (client.includes('localhost')) {
        return 'local';
    }
    if (client.includes('finzap')) {
        return 'finzap';
    }
    if (client.includes('procord')) {
        return 'procord';
    }
    if (client.includes('mifianza')) {
        return 'mifianza';
    }
    if (client.includes('fembisol')) {
        return 'fembisol';
    }
    if (client.includes('suvalor')) {
        return 'suvalor';
    }
    if (client.includes('crecevalor')) {
        return 'crecevalor';
    }
    if (client.includes('appqa2')) {
        return 'appqa2';
    }
    if (client.includes('mili')) {
        return 'mili';
    }
    if (client.includes('apprework')) {
        return 'apprework';
    }
    if (client.includes('zali')) {
        return 'zali';
    }
    if (client.includes('finnvo')) {
        return 'finnvo';
    }
    if (client.includes('bancaliv')) {
        return 'bancaliv';
    }
    if (client.includes('creditoo')) {
        return 'creditoo';
    }
    if (client.includes('inversiones')) {
        return 'inversiones';
    }
    if (client.includes('fie')) {
        return 'fie';
    }
    if (client.includes('fibanca')) {
        return 'fibanca';
    }
    if (client.includes('tusolucion')) {
        return 'tusolucion';
    }
    if (client.includes('credipress')) {
        return 'credipress';
    }
    if (client.includes('liberate')) {
        return 'liberate';
    }
    if (client.includes('nauw')) {
        return 'nauw';
    }
    if (client.includes('lenddi')) {
        return 'lenddi';
    }
    if (client.includes('credinver')) {
        return 'credinver';
    }
    if (client.includes('freend')) {
        return 'freend';
    }
    if (client.includes('gmserviciosfinancieros')) {
        return 'gym';
    }
    if (client.includes('maxcredit')) {
        return 'maxcredit';
    }
    if (client.includes('sofi')) {
        return 'sofi';
    }
    if (client.includes('libramoneda')) {
        return 'libramoneda';
    }
    if (client.includes('qbcol')) {
        return 'quickbank';
    }
    if (client.includes('appqa2')) {
        return 'appqa2';
    }
    if (client.includes('appqa')) {
        return 'appqa';
    }
    if (client.includes('finanzas')) {
        return 'finanzas';
    }

    return 'mili';
};