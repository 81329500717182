import moment from 'moment'
import { calculator } from './calculator'

/**
 * actualiza todo los estados para procesar el crédito
 * @param {object} args argumentos para el calculo del crédito
 * @return {object} nuevos argumentos
 */
export const calculatorCreditSelect = ({ payment, newMoney, params, newMonth, payroll, disbursement, credits }) => {
    const { valueCredit, ctName, ctModule, ctType, month, vat, rate, ctRatVal, creditTypeAttributes, ctReTanking, ctOptPay, disableReTanking, ctMaxCap, ctGracePeriod, ctPayGraceInterest } = params
    const net = ((payroll?.payrollWork?.pwNet || 0) / 2) // - (!disableReTanking ? ctReTanking === 1 ? 0 : payment : payment)
    const salary = payroll?.payrollWork?.pwSalary
    let mon = newMonth || month?.mValue
    let ratMon = 1
    let dayCou
    let dateEnd
    let permission = false
    let uniqueTanking = false

    /** Verifica si es por mes o por dia */
    if (ctModule === 1) {
        /** fecha actual y fecha con los dias de anticipo mínimo */
        const dateStart = moment()
        const dateMedium = moment().add(mon, 'd')
        if (ctType === 1) {
            /** Calcula la fecha de pago de la cuota */
            dateEnd = moment([dateMedium.format('YYYY'), Number(dateMedium.format('MM')) - 1, new Date(dateMedium.format('YYYY'), dateMedium.add(1, 'm').format('MM'), 0).getDate()])

            /** calcula la diferencia en dias de pagos para la tasa */
            const days = dateEnd.diff(dateStart, 'd') + 1
            dayCou = days >= 30 ? 30 : days
            ratMon = dayCou / 30
        } else {
            /** fechas de prima de junio y diciembre */
            const actualYear = Number(moment().format('YYYY'))
            dateEnd = Number(dateStart.format('MM')) > 6 ? moment(`${actualYear}-12-15`) : moment(`${actualYear}-06-30`)

            /** Verifica si se encuentra en los parámetros de poder solicitar una prima */
            if (valueCredit?.month?.mValue < dateEnd.diff(dateStart, 'month') || dateEnd.diff(dateStart, 'days') < mon) {
                return {
                    error: true,
                    message: 'No es posible solicitar el crédito, por que excede el máximo de meses permitido.',
                    data: { ctId: undefined, vcDefVal: 0, vcValMin: 0, vcMaxVal: 0, mMinVal: 0, mMaxVal: 0, mDefVal: 0, valCre: 0, valCou: 0 }
                }
            }

            /** calcula la diferencia en dias de pagos para la tasa */
            const days = dateEnd.diff(dateStart, 'd') + 1
            dayCou = days
            ratMon = dayCou / 30
            permission = true
        }

        /** Diferencias de meses para actualizar de cuantos meses de uso */
        mon = 1
    } else {
        if (ctType === 1) {
            if (ctGracePeriod && ctPayGraceInterest !== 1) {
                const num = 1 + (ctGracePeriod || 0)
                dateEnd = moment().add(num, 'month').format('YYYY-MM-DD')
            } else {
                dateEnd = moment().add(1, 'month').format('YYYY-MM-DD')
            }
        }
        else {
            if (ctGracePeriod && ctPayGraceInterest !== 1) {
                const num = (7 + (7 * (ctGracePeriod || 0)))
                dateEnd = moment().add({ days: num }).format('YYYY-MM-DD')
            } else {
                dateEnd = moment().add(7, 'd').format('YYYY-MM-DD')
            }
            ratMon = (7 / 30) * 7
        }
    }
    // calcula el valor del crédito
    const { valCou, statusBond, statusLife } = calculator({
        attributes: creditTypeAttributes,
        money: disbursement || newMoney || (ctModule === 1 ? net : ((salary * valueCredit?.vcDefVal > ctMaxCap && ctMaxCap > 0) ? ctMaxCap : salary * valueCredit?.vcDefVal)),
        mon,
        iva: vat?.vPor,
        ctModule,
        rPor: ctRatVal ?? rate?.rPor,
        ratMon
    })
    if ((disbursement || newMoney || (ctModule === 1 ? net : ((salary * valueCredit?.vcDefVal > ctMaxCap && ctMaxCap > 0) ? ctMaxCap : salary * valueCredit?.vcDefVal))) < 0 && !permission) {
        if (ctReTanking === 1 && !disableReTanking) uniqueTanking = true
        else {
            return {
                error: true,
                message: 'No es posible seleccionar la cuota, su cupo es menor al monto mínimo de la línea de crédito.',
                data: { ctId: undefined, vcDefVal: 0, vcValMin: 0, vcMaxVal: 0, mMinVal: 0, mMaxVal: 0, mDefVal: 0, valCre: 0, valCou: 0 }
            }
        }
    }

    // verifica el monto si es disponible a pagar
    const value = net - Math.round(valCou)

    if (value <= 0 && !permission && (disbursement > 0 || disbursement === undefined)) return calculatorCreditSelect({ payment, params, payroll, newMonth, disbursement: (disbursement ? (disbursement * 0.9) : (newMoney || net * 0.9)) })

    // calcula el mínimo y máximo del crédito
    const vcValMin = Math.round(salary * valueCredit?.vcMinVal)
    const vcMaxVal = (ctMaxCap > 0 && ctMaxCap < Math.round(disbursement || newMoney || salary * valueCredit?.vcMaxVal)) ? ctMaxCap : Math.round(disbursement || newMoney || salary * valueCredit?.vcMaxVal)

    if ((vcValMin > vcMaxVal || (vcValMin > valCou && mon === 1)) && !permission) {
        if ((ctReTanking === 1 || ctOptPay === 1) && !disableReTanking) {

            // verifica si existe de retanqueo
            if (ctReTanking === 1) {
                const resC = !!credits?.find(x => x.cName === ctName && x.cState === 6)

                if (resC) uniqueTanking = resC
                else {
                    return {
                        error: true,
                        message: 'No es posible seleccionar la cuota, su cupo es menor al monto mínimo de la línea de crédito.',
                        data: { ctId: undefined, vcDefVal: 0, vcValMin: 0, vcMaxVal: 0, mMinVal: 0, mMaxVal: 0, mDefVal: 0, valCre: 0, valCou: 0 }
                    }
                }
            }

            // verifica que exista un crédito en desembolso
            if (ctOptPay === 1) {
                const resC = !!credits?.find(x => x.cState === 6)

                if (resC) uniqueTanking = resC
                else {
                    return {
                        error: true,
                        message: 'No es posible seleccionar la cuota, su cupo es menor al monto mínimo de la línea de crédito.',
                        data: { ctId: undefined, vcDefVal: 0, vcValMin: 0, vcMaxVal: 0, mMinVal: 0, mMaxVal: 0, mDefVal: 0, valCre: 0, valCou: 0 }
                    }
                }
            }
        } else {
            return {
                error: true,
                message: 'No es posible seleccionar la cuota, su cupo es menor al monto mínimo de la línea de crédito.',
                data: { ctId: undefined, vcDefVal: 0, vcValMin: 0, vcMaxVal: 0, mMinVal: 0, mMaxVal: 0, mDefVal: 0, valCre: 0, valCou: 0 }
            }
        }
    }

    // saca el total de fechas de pagos para el avance de nomina
    const datPays = []
    if (ctModule === 1) {
        const lengthI = (ctType === 1 && dayCou < 30) ? valueCredit?.month?.mValue : 1
        for (let i = 0; i < lengthI; i++) {
            dateEnd.add(i >= 1 ? 1 : 0, 'month')
            const months = (Number(dateEnd.format('MM')) - 1)
            const newDate = moment([dateEnd.format('YYYY'), months, new Date(dateEnd.format('YYYY'), months + 1, 0).getDate()])

            datPays.push({ id: i + 1, name: newDate.format('MMMM YYYY'), value: newDate.format('DD-MM-YYYY'), date: newDate.format('YYYY-MM-DD') })
        }
    }

    /** actualiza las variables */
    return {
        data: {
            vcValMin,
            vcMaxVal,
            vcDefVal: (Math.round(disbursement || newMoney || salary * valueCredit?.vcDefVal) > ctMaxCap && ctMaxCap > 0) ? ctMaxCap : Math.round(disbursement || newMoney || salary * valueCredit?.vcDefVal),
            mMinVal: valueCredit?.month?.mValue,
            mMaxVal: month?.mValue,
            mDefVal: newMonth || month?.mValue,
            valCou: valCou > 0 ? Math.round(valCou) : 0,
            ctModule,
            statusBond,
            statusLife,
            datPays,
            dayCou,
            dateEnd: datPays?.length ? datPays[0].date : dateEnd,
            datPay: datPays?.length ? datPays[0].name : undefined,
            permission,
            uniqueTanking
        }
    }
}