export const TerCon = ({
    name,
    nameBrand
}) => {
    return [
        {
            id: 1,
            title: `TÉRMINOS Y CONDICIONES ${nameBrand}`,
            message: `Los presentes Términos y Condiciones corresponden a los lineamientos generales de los servicios prestados por la sociedad ${nameBrand}, identificada con NIT. 000.000.000 – 0, en adelante reconocida como ${name}.`,
        },
        {
            id: 2,
            title: 'CONOCIMIENTO',
            message: `Para el conocimiento de todos, el presente documento rige el uso de los servicios prestados por ${name}, de manera que toda persona que solicite y acceda a los mismos se verá en la obligación de conocerlos y acogerse a su cumplimiento, así como actualizarse en cuanto a los cambios y ajustes que los mismos puedan sufrir.`,
        },
        {
            id: 3,
            title: 'ACEPTACIÓN',
            message: `De conformidad con el inciso previo, quién ingresa a ${name}, acepta de manera libre y voluntaria las obligaciones, los deberes, así como cada una de las responsabilidades estipuladas en el presente documento, y las que se deriven o lleguen a derivar. Así mismo, toda relación contractual acordada con ${name} se rige, en lo que competa a los presentes Términos y Condiciones que se entienden aceptados por la Parte suscriptora, razón por la que serán plenamente vinculantes, en toda y cada una de sus directrices, obligaciones, deberes y derechos.`,
        },
        {
            id: 4,
            message: `Los usuarios reconocen y aceptan de forma libre que estos Términos y Condiciones podrán ser susceptibles de ajustes y modificaciones, de forma unilateral y a criterio de ${name}, quien ostenta amplias facultades para realizar dichos cambios, dentro del marco normativo vigente en la República de Colombia. Por lo tanto, es deber de todas las personas revisar las mismas y sus actualizaciones.`,
        },
        {
            id: 5,
            title: 'DEFINICIONES',
            message: `USUARIO: Toda persona que haga uso del aplicativo https://app.procord.com.co para los fines correspondientes a solicitud de crédito, revisión de productos crediticios con ${name} y cualquier otro uso que corresponda. `,
        },
        {
            id: 6,
            message: `CLIENTE: Toda persona que tenga un producto vigente con ${name}, haciendo uso directo, indirecto o remoto de los canales de prestación de servicios de ${name}. `,
        },
        {
            id: 7,
            message: 'MUTUANTE:  Parte contractual que otorga el crédito al MUTUARIO.',
        },
        {
            id: 8,
            message: 'MUTUARIO: Parte contractual que recibe el desembolso del préstamo y se obliga con el MUTUANTE a pagar el valor total del crédito.',
        },
        {
            id: 9,
            message: 'AFIANZADOR: Parte contractual que otorga garantía o se hace responsable de asegurar el cumplimiento de la obligación que El MUTUARIO asume con EL MUTUANTE, es decir, otorga fianza.',
        },
        {
            id: 10,
            message: 'FIANZA:  La fianza es una obligación accesoria, en virtud de la cual una o más personas responden de una obligación ajena, comprometiéndose para con el acreedor a cumplirla en todo o parte, si el deudor principal no la cumple, es decir, esta es una garantía, que brinda el DEUDOR, previa aceptación y autorización para adicionarlo al crédito a su costa al momento del desembolso del crédito, en caso de incumplimiento y por lo tanto beneficia directamente al deudor en caso de incumplimiento. El servicio previamente mencionado podrá ser contratado por la entidad de preferencia del DEUDOR previa aceptación y validación del ACREEDOR o podrá contratarse a la entidad reconocida y sugerida por el ACREEDOR, previa aceptación del DEUDOR. ',
        },
        {
            id: 11,
            message: 'ESTUDIO: Proceso mediante el cual se realiza la verificación de información del mutuario o afianzador, por medio de plataformas de información, que nos permitan corroborar la veracidad de los datos, para realizar un análisis de riesgo del MUTUARIO (DEUDOR) y los codeudores a los que hubiera lugar, para poder celebrar el contrato de mutuo y de fianza.',
        },
        {
            id: 12,
            message: `GASTOS DE ESTRUCTURACIÓN DEL CRÉDITO: Son los costos asociados con el proceso de diseño, análisis, evaluación y otorgamiento del crédito por parte de ${name}. Estos gastos son los costos administrativos y operativos en que la institución incurre al evaluar la solicitud de préstamo, determinar la elegibilidad del solicitante y establecer los términos y condiciones del crédito, los cuales serán adicionados al momento del desembolso al valor total del crédito.`,
        },
        {
            id: 13,
            message: 'VALOR DE DESEMBOLSO: Es la cantidad de dinero que se deposita en la cuenta del DEUDOR o se entrega en efectivo, teniendo en cuenta las deducciones por costos adicionales y factores como tasas de interés, seguros u otros cargos asociados al préstamo, como los gastos de estructuración del crédito, seguro de vida, fianza y gastos financieros que son asumidos y adicionados, previa autorización y aceptación al valor del crédito aprobado por el deudor al momento del desembolso de su crédito.',
        },
        {
            id: 14,
            message: 'SEGURO DE VIDA DEUDORES: Es un requisito asociado al crédito de libre inversión, el cual está diseñado para proteger al DEUDOR y a la entidad financiera en caso de que el DEUDOR, presente alguna discapacidad, incapacidad o fallezca durante el plazo del préstamo. Su propósito principal es cubrir el saldo pendiente del préstamo en caso de fallecimiento del DEUDOR, liberando a los familiares o herederos del peso financiero de la deuda.',
        },
        {
            id: 15,
            message: `Es esencial destacar que la contratación del seguro de vida de deudores podrá ser contratado por la entidad de preferencia del DEUDOR o podrá realizarse por medio de la entidades reconocidas y sugeridas por el ACREEDOR, lo que conlleva a un costo adicional el cual se añade al valor total del crédito a favor del deudor, en caso de que este así lo elija, y se aplica en el momento del desembolso del crédito, a menos que el deudor opte por constituir este seguro con una entidad diferente a su costa, siempre y cuando dicha entidad esté legalmente autorizada y cumpla con las condiciones establecidas por ${name}.`,
        },
        {
            id: 16,
            message: 'REEMBOLSO DE GASTOS FINANCIEROS: Es el proceso de devolver a la entidad financiera los costos adicionales relacionados con la obtención y gestión del préstamo.',
        },
        {
            id: 17,
            message: 'VALOR DEL CRÉDITO: Es el monto neto que autoriza y asume el DEUDOR después de deducir las comisiones y  los costos asociados al préstamo a su costa, el cual en ningún caso será igual al valor de desembolso, debido a los cargos asociados al préstamo, como los gastos de estructuración del crédito, seguro de vida, fianza y gastos financieros que son asumidos y adicionados al préstamo y al valor total del crédito, previa autorización y aceptación para el pago a terceros por el deudor al momento del desembolso del crédito a su favor, salvo que se asuman de manera previa al desembolso.',
        },
        {
            id: 18,
            message: 'DERECHOS DE FIANZA: Son gastos cobrados al MUTUARIO (DEUDOR) por los gastos que se incurren en la legalización de la relación contractual y prestación del servicio, con las tarifas que podrán ser consultadas en el sitio web.',
        },
        {
            id: 19,
            message: 'FORMATO DE RECLAMACIÓN: Documento en el cual se reporta el incumplimiento del ACREEDOR (DEUDOR), como deudor principal, frente a una o todas las obligaciones garantizadas por medio del contrato de fianza.',
        },
        {
            id: 20,
            title: 'PROCEDIMIENTO',
        },
        {
            id: 21,
            title: '1.  Registro:',
            message: `Se realiza en el portal web https://app.procord.com.co, en donde debe seleccionar el perfil según corresponda y diligenciar toda la información contenida en el formulario de registro, la cual debe ser exacta, precisa y verdadera, previo a la aceptación de nuestra política de tratamiento y protección de datos, así como las autorizaciones según correspondan. ${name} podrá utilizar algunas plataformas de información, que le permitan identificar la titularidad de las personas y la información registrada en estos formularios, por lo que con la aceptación de las autorizaciones se emite consentimiento abierto, rotundo, expreso, libre y suficiente para lo anterior.`,
        },
        {
            id: 22,
            title: '2.  Estudio',
            message: `Proceso mediante el cual se realiza la verificación de información registrada en el formulario de solicitud de afianzamiento, haciendo uso de plataformas de información, que permitan corroborar la veracidad de los datos, para así realizar el análisis de riesgo del MUTUARIO (DEUDOR) y los codeudores a los que hubiera lugar, para poder celebrar el contrato de mutuo. El estudio del crédito no garantiza la aprobación del mismo. La política usada por ${name} para la aprobación del MUTUARIO (DEUDOR), dependerá de su historial, comportamiento crediticio y capacidad de pago, por esta razón ${name} se reserva el derecho de prestar o no sus servicios.`,
        },
        {
            id: 23,
            title: '3.  Elaboración y firma Contrato de mutuo de fianza:',
            message: 'Una vez realizado el estudio y teniendo como resultado la aprobación del MUTUARIO (DEUDOR) y codeudores, si los hubiere, se procederá con la elaboración y suscripción del contrato de mutuo y de fianza, finalmente se remite el contrato de forma digital para que sea firmado por el MUTUARIO (DEUDOR).',
        },
        {
            id: 24,
            title: 'PETICIONES, QUEJAS Y RECLAMOS',
            message: 'Para presentar una petición, queja o reclamo (PQR), los usuarios podrán enviarlo al correo electrónico info@procord.com.co o si lo prefiere a las instalaciones de la compañía que se encuentran relacionadas en nuestro sitio web.',
        },
        {
            id: 25,
            message: 'Cada PQR deberá contener, como mínimo, la siguiente información:',
        },
        {
            id: 26,
            message: '1.    Nombre completo.',
        },
        {
            id: 27,
            message: '2.    Número de identificación.',
        },
        {
            id: 28,
            message: '3.    Firma del peticionario',
        },
        {
            id: 29,
            message: '4.    Correo electrónico.',
        },
        {
            id: 30,
            message: '5.    Número de teléfono.',
        },
        {
            id: 31,
            message: '6.    Dirección del inmueble.',
        },
        {
            id: 32,
            message: '7.    Breve explicación de su petición, queja o reclamo.',
        },
        {
            id: 33,
            message: '8.    Pruebas, si las tiene, sobre su PQR.',
        },
        {
            id: 34,
            message: 'Cada una de las PQR será contestada dentro de los quince (15) días hábiles siguientes a la recepción de la mismo. ',
        },
        {
            id: 35,
            title: 'DECLARACIONES',
            message: `PRIMERA: Los usuarios declaran que la aceptación de los presentes Términos y Condiciones no obliga a ${name} a aprobar el estudio de crédito, ni a suscribir contrato o acuerdo con los usuarios.`,
        },
        {
            id: 36,
            message: `SEGUNDA: Los usuarios declaran que conocen que el funcionamiento del sitio web, aplicativo web y sus anexidades no depende en un cien por ciento (100%) de ${name}, por lo que acepta que en caso de error o problemas en la plataforma eximirá de toda culpa a ${name}. En cualquier caso, ${name} pondrá su mayor esfuerzo y a disposición de los usuarios los canales necesarios para la resolución de las dificultades presentadas, siempre que esté al alcance y en cuanto corresponda a su responsabilidad.`,
        },
        {
            id: 37,
            message: `TERCERA: Todos los datos recolectados serán almacenados y utilizados bajo los más altos estándares de cuidado y diligencia, de acuerdo a lo establecido en la normatividad vigente, y para los fines de las actividades y servicios prestados por ${name}. Para mayor información los usuarios podrán consultar la Política de Tratamiento de Datos Personales, que se encuentra en el sitio web de ${name}`,
        },
        {
            id: 38,
            message: `CUARTA: Los usuarios declaran que reconocen y aceptan que ${name} es el propietario y titular de la marca y demás elementos de la identidad visual que en ella se consignan, por lo que les queda expresamente prohibido hacer uso de lo mencionado, a título personal o por Intermedio de un tercero.`,
        },
        {
            id: 39,
            message: `QUINTA: Los usuarios de la plataforma con la aceptación de los presentes Términos y Condiciones, declaran bajo la gravedad del juramento que sus ingresos provienen de actividades lícitas, que no se encuentran con registro negativo en listados de prevención de lavado de activos y/o financiación del terrorismo nacionales o internacionales, que no se encuentran dentro de una de las dos categorías de lavado de activos (conversión o movimiento), ni aparecen en listas de Control o de riesgo de Agencias Internacionales (INTERPOL, EUROPOL, ONU,OFAC, DEA, FBI, etc.) y/u Organismos de Control Nacional (PROCURADURÍA, CONTRALORÍA, FISCALÍA, POLICÍA, CTI, GAULA, etc.), por hechos relacionados con lavado de activos y/o financiación del terrorismo y que en consecuencia, se obligan a responder ante ${name} por todos los perjuicios que se llegaren a causar a ella o a terceras personas vinculadas con la misma, en caso de incumplir la presente declaración.`,
        },
        {
            id: 40,
            message: `SEXTA: Por medio de la solicitud de crédito, por medio del aplicativo https://app.procord.com.co y/o por cualquier otro medio virtual o físico que utilice ${name} para ofrecer sus productos y servicios de crédito, los USUARIOS de ${name} declaran que conocen, entiende y aceptan los gastos asociados al crédito y su incorporación en la suma total del valor del crédito, los cuales en caso de no ser pagados de forma previa o directamente a terceros, serán sumados a dicho valor, lo cual es informado y aceptado de manera previa a la suscripción de los documentos de crédito por EL CLIENTE.`,
        },
    ];
}