import { gql } from '@apollo/client'

export const USE_ONE_CREDIT_INFO = gql`
    query getOneCredit($state: [Int], $cId: ID) {
        getCreditById(cId: $cId, state: $state) {
            cId
            cConsecutive
            cName
            cModule
            cType
            cReqVal
            cMon
            cRatVal
            cVatVal
            cValCre
            cValCou
            cBalance
            cFirFee
            cDayCou
            cAdjustable
            cValAdj
            cState
            creditAttributeFees {
                cafId
                cafName
                cafPriority
                cafAmount
            }
            creditFees {
                cfId
                cfDate
                cfMonth
                cfAmount
                cfCapital
                cfAnnulled
                cfIdParent
                creditFeeAttributes {
                    cfaId
                    caId
                    cfaAmount
                    cfaState
                }
            }
            creditAttributes {
                caId
                caType
                caFormula
            }
            creditFormat {
                cfId
                cfReqValAft
                cfValCreAft
                cfRatAft
                cfValCouAft
                cfFirFeeAft
                cfMonAft
                cfState
            }
        }
    }
`

export const CALCULATE_FEE = gql`
    query CalculateFee($cId: String, $feeQuantity: Int) {
        CalculateFee(cId: $cId, feeQuantity: $feeQuantity) {
          totalInterestArrears
          totalCollectionCostFee
          totalFee
        }
    }
`