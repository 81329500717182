import { calculator } from '../Credits/calculator'
import { calculateRepayment } from './calculateRepayment'
import { calculatorAttribute } from './calculatorAttribute'

/**
 * calcula el valor del crédito de cartera
 * @param {object} args argumentos
 * @return {object} nuevos argumentos
 */
export function calculateCreditCard ({ month, vat, rate, ctRatVal, creditTypeAttributes, totalCard }) {
    // calcula el valor del crédito
    const data = calculator({ attributes: creditTypeAttributes, money: totalCard, mon: month?.mValue, iva: vat?.vPor, ctModule: 2, rPor: rate?.rPor, ratMon: 1 })

    // calcula el valor de los atributos
    const dataAtt = calculatorAttribute({ attributes: creditTypeAttributes, valCre: data?.valCre, reqVal: totalCard, mon: month?.mValue, iva: vat?.vPor })

    // calcula el plan de amortización
    const repaymentPlan = calculateRepayment({ valCre: data?.valCre, valCou: data?.valCou, fees: dataAtt?.fees, rate: ctRatVal ?? rate?.rPor, month: month?.mValue })

    return { ...data, ...dataAtt, reqVal: totalCard, month: month?.mValue, rate: ctRatVal ?? rate?.rPor, repaymentPlan }
}