import { gql } from '@apollo/client'

export const USE_ALL_CREDITS = gql`
query getAllCredits($state: [Int], $order: [[String]], $calculateBalanceToday: Boolean, $pcId: ID) {
        credits(state: $state, order: $order, calculateBalanceToday: $calculateBalanceToday, pcId: $pcId) {
            cId
            cName
            cConsecutive
            cReqVal
            cRatVal
            cValCou
            cBalance
            cValCre
            cPending
            cEdit
            cCard
            cCarDoc
            cAddInf
            cState
            cFirFee
            cPayDat
            cIs360
            creditPayrollCompany {
                cpcId
            }
            creditCards {
                ccId
                ccName
                ccFee
                ccBalance
                ccDocument
            }
            creditFormat {
                cfId
                cfRatAft
                cfValCouAft
                cfReqValAft
                cfState
            }
            creditFees {
                cfId
                cfMonth
                toId
                cfAmount
                cfCapital
                creditFeeAttributes {
                    cfaId
                    caId
                    cfaAmount
                }
                cfDatCre
            }
            creditAttributeFees {
                cafName
                cafAmount
            }
            creditOrigins {
                coId
                coApplied
                toId
                coAmount
                coDate
                icId
                cId
                coState
            }
          balanceToday
        }

    }
`
export const GET_ELECTRONIC_PAYMENT_PROVIDER = gql`
query ElectronicPaymentProviders {
  electronicPaymentProviders {
    idEPP
    name
    key
    state
  }
}
`
export const GET_USER_BY_ID = gql`
query GetUserById($uId: ID, $state: [Int]) {
  getUserById(uId: $uId, state: $state) {
    uId
    uIdeNum
    adoUserEnrollment {
      uId
      idAP
      transactionId
      adoStateId
      idAUE
      adoParameter {
        idAP
        apiKey
        projectName
      }
    }
  }
}
`
export const UPDATE_ADO_USER_ENROLLMENT = gql`
mutation UpdateAdoUserEnrollment($input: IAdoUserEnrollments!, $idAue: ID) {
  updateAdoUserEnrollment(input: $input, idAUE: $idAue) {
    idAUE
    adoStateId
  }
}
`
export const ADO_PARAMETERS = gql`
  query AdoParameters($state: [Int], $onRegister: [Int]) {
    adoParameters(state: $state, onRegister: $onRegister) {
      idAP
      projectName
      apiKey
      secretKey
      urlTest
      urlProd
      isProd
      brand
      onRegister
      state
    }
  }
`;

export const GET_PAYROLL_COMPANY_BY_ID = gql`
  query GetPayrollCompanyById($pcIdeNum: String) {
    getPayrollCompanyById(pcIdeNum: $pcIdeNum) {
      pcId
      pcIdeNum
      payrollStaff {
        psEmail
      }
    }
  }
`

export const CREDIT_ALL_INFORMATION = gql`
  query CreditAllInformation($pcId: String) {
    CreditAllInformation(pcId: $pcId) {
      currentBalance
    }
  }
`